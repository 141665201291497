/*eslint-disable*/
import { Flex, Link, List, ListItem, Text } from '@chakra-ui/react';
import React from 'react';

export default function Footer(props) {
  return (
    <Text
      color="gray.400"
      textAlign={{
        base: 'center',
        xl: 'center',
      }}
      mb={{ base: '20px', xl: '0px' }}
    >
      {/* 2023, <Text as="span">Provided ❤️ by </Text>
      <Link color="blue.900" href="https://bfd.vn" target="_blank">
        BFD
      </Link> */}
    </Text>
  );
}
