// Chakra imports
import { Text, Tr, Td, useColorModeValue } from '@chakra-ui/react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
// Custom components
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { invoiceActions } from 'redux/actions';
import { currencyFormat } from 'common';

const TableRow = ({ isLast, row, saveInvoiceStatus }) => {
  const { isSavingInvoice } = useSelector((state) => state.invoiceReducer);
  const history = useHistory();
  const dispatch = useDispatch();
  const textColor = useColorModeValue('gray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  const gotoApprove = () => {
    if (isSavingInvoice) return;
    if (saveInvoiceStatus === 0) {
      dispatch(
        invoiceActions.saveInvoice(row.id, {
          success: (res) => {
            history.push('order-add-sell', { isApprove: true, row: res });
          },
          failed: (mess) => {
            console.warn(`Lỗi: ${mess}`);
          },
        })
      );
    } else {
      dispatch(
        invoiceActions.saveInvoice(row.id, {
          success: (res) => {
            history.push('order-add-sell', { isDetail: true, row: res });
          },
          failed: (mess) => {
            console.warn(`Lỗi: ${mess}`);
          },
        })
      );
    }
  };

  return (
    <Tr>
      <Td
        fontSize={[10, 10, 12, 12]}
        px={['5px', '5px', '24px', '24px']}
        py={['5px', '5px', '12px', '12px']}
        borderColor={borderColor}
        borderBottom={isLast ? 'none' : null}
      >
        <Text
          onClick={gotoApprove}
          fontSize="sm"
          color={'#3182ce'}
          fontWeight="bold"
          pb=".5rem"
          textAlign={'center'}
          className="approve"
        >
          {saveInvoiceStatus === 0 ? 'Duyệt' : 'Chi tiết'}
        </Text>
      </Td>
      <Td
        minWidth={{ sm: '250px' }}
        pl="0px"
        fontSize={[10, 10, 12, 12]}
        px={['5px', '5px', '24px', '24px']}
        py={['5px', '5px', '12px', '12px']}
        borderColor={borderColor}
        borderBottom={isLast ? 'none' : null}
      >
        <Text fontSize="sm" color={textColor} fontWeight="bold" pb=".5rem">
          {!!row?.saleInvoice?.PostedDate
            ? moment(row.saleInvoice.PostedDate).format('DD/MM/YYYY')
            : ''}
        </Text>
      </Td>

      <Td
        fontSize={[10, 10, 12, 12]}
        px={['5px', '5px', '24px', '24px']}
        py={['5px', '5px', '12px', '12px']}
        borderColor={borderColor}
        borderBottom={isLast ? 'none' : null}
      >
        <Text fontSize="sm" color={textColor} fontWeight="bold" pb=".5rem">
          {!!row?.saleInvoice?.VoucherDate
            ? moment(row.saleInvoice.VoucherDate).format('DD/MM/YYYY')
            : ''}
        </Text>
      </Td>
      <Td
        fontSize={[10, 10, 12, 12]}
        px={['5px', '5px', '24px', '24px']}
        py={['5px', '5px', '12px', '12px']}
        borderColor={borderColor}
        borderBottom={isLast ? 'none' : null}
      >
        <Text fontSize="sm" color={textColor} fontWeight="bold" pb=".5rem">
          {row?.saleInvoice?.INVoucherNo ?? ''}
        </Text>
      </Td>
      <Td
        fontSize={[10, 10, 12, 12]}
        px={['5px', '5px', '24px', '24px']}
        py={['5px', '5px', '12px', '12px']}
        borderColor={borderColor}
        borderBottom={isLast ? 'none' : null}
      >
        <Text fontSize="sm" color={textColor} fontWeight="bold" pb=".5rem">
          {row?.saleInvoice?.INJournalMemo ?? ''}
        </Text>
      </Td>
      <Td
        fontSize={[10, 10, 12, 12]}
        px={['5px', '5px', '24px', '24px']}
        py={['5px', '5px', '12px', '12px']}
        borderColor={borderColor}
        borderBottom={isLast ? 'none' : null}
      >
        <Text fontSize="sm" color={textColor} fontWeight="bold" pb=".5rem">
          {row?.saleInvoice?.AccountingObjectName ?? ''}
        </Text>
      </Td>
      <Td
        fontSize={[10, 10, 12, 12]}
        px={['5px', '5px', '24px', '24px']}
        py={['5px', '5px', '12px', '12px']}
        borderColor={borderColor}
        borderBottom={isLast ? 'none' : null}
      >
        <Text fontSize="sm" color={textColor} fontWeight="bold" pb=".5rem">
          {row?.saleInvoice?.VoucherType ?? ''}
        </Text>
      </Td>
      <Td
        fontSize={[10, 10, 12, 12]}
        px={['5px', '5px', '24px', '24px']}
        py={['5px', '5px', '12px', '12px']}
        borderColor={borderColor}
        borderBottom={isLast ? 'none' : null}
      >
        <Text
          fontSize="sm"
          textAlign={'right'}
          color={textColor}
          fontWeight="bold"
          pb=".5rem"
        >
          {currencyFormat(row?.saleInvoice?.TotalAmount ?? 0)}
        </Text>
      </Td>
      <Td
        fontSize={[10, 10, 12, 12]}
        px={['5px', '5px', '24px', '24px']}
        py={['5px', '5px', '12px', '12px']}
        borderColor={borderColor}
        borderBottom={isLast ? 'none' : null}
      >
        <Text
          fontSize="sm"
          color={textColor}
          fontWeight="bold"
          pb=".5rem"
          textAlign={'right'}
        >
          {currencyFormat(row?.saleInvoice?.TotalOutwardAmount ?? 0)}
        </Text>
      </Td>
    </Tr>
  );
};

export default TableRow;
