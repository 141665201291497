import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import TypeActions from 'redux/typeActions';
import ServiceURL from 'services/ServiceURL';
import { GET } from 'services/ServiceBase';

export function* getCompanys(data) {
  const url = ServiceURL.companys + '?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);

    if (res.message && !_.isEmpty(res?.message)) {
      yield put({
        type: TypeActions.GET_COMPANYS_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.GET_COMPANYS_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_COMPANYS_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export default function* companySaga() {
  yield takeLatest(TypeActions.GET_COMPANYS_REQUEST, getCompanys);
}
