// Chakra imports
import {
  Text,
  Tr,
  Td,
  useColorModeValue,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
} from '@chakra-ui/react';
import IconBox from 'components/Icons/IconBox';
import { DeleteIcon, EditIcon } from 'components/Icons/Icons';
import moment from 'moment';
// Custom components
import React from 'react';

const TableRow = ({ isLast, row, onEdit, onDelete }) => {
  const textColor = useColorModeValue('gray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  let variantChange = '0.2s linear';
  return (
    <Tr>
      <Td
        minWidth={{ sm: '250px' }}
        pl="0px"
        fontSize={[10, 10, 12, 12]}
        px={['5px', '5px', '24px', '24px']}
        py={['5px', '5px', '12px', '12px']}
        borderColor={borderColor}
        borderBottom={isLast ? 'none' : null}
      >
        <Text fontSize="sm" color={textColor} fontWeight="bold" pb=".5rem">
          {row?.name ?? ''}
        </Text>
      </Td>

      <Td
        fontSize={[10, 10, 12, 12]}
        px={['5px', '5px', '24px', '24px']}
        py={['5px', '5px', '12px', '12px']}
        borderColor={borderColor}
        borderBottom={isLast ? 'none' : null}
      >
        <Text fontSize="sm" color={textColor} fontWeight="bold" pb=".5rem">
          {row?.email ?? ''}
        </Text>
      </Td>
      <Td
        fontSize={[10, 10, 12, 12]}
        px={['5px', '5px', '24px', '24px']}
        py={['5px', '5px', '12px', '12px']}
        borderColor={borderColor}
        borderBottom={isLast ? 'none' : null}
      >
        <Text fontSize="sm" color={textColor} fontWeight="bold" pb=".5rem">
          {!!row?.createdAt ? moment(row.createdAt).format('DD/MM/YYYY') : ''}
        </Text>
      </Td>
      <Td
        fontSize={[10, 10, 12, 12]}
        px={['5px', '5px', '24px', '24px']}
        py={['5px', '5px', '12px', '12px']}
        display={'flex'}
        flexDir={'row'}
        justifyContent={'center'}
        borderColor={borderColor}
        borderBottom={isLast ? 'none' : null}
      >
        <Button
          type="button"
          boxSize="initial"
          justifyContent="center"
          alignItems="center"
          boxShadow={'0px 7px 11px rgba(0, 0, 0, 0.04)'}
          bg={'transparent'}
          transition={variantChange}
          borderRadius="15px"
          h="48px"
          w="48px"
          me={'8px'}
          _active={{
            bg: 'inherit',
            transform: 'none',
            borderColor: 'transparent',
          }}
          _hover={{
            boxShadow: '0px 7px 11px rgba(0, 0, 0, 0.2)',
          }}
          onClick={onEdit}
        >
          <IconBox h="30px" w="30px" transition={variantChange}>
            <EditIcon color={'#3182ce'} />
          </IconBox>
        </Button>
        <Popover>
          <PopoverTrigger>
            <Button
              type="button"
              boxSize="initial"
              justifyContent="center"
              alignItems="center"
              boxShadow={'0px 7px 11px rgba(0, 0, 0, 0.04)'}
              bg={'transparent'}
              transition={variantChange}
              borderRadius="15px"
              h="48px"
              w="48px"
              _active={{
                bg: 'inherit',
                transform: 'none',
                borderColor: 'transparent',
              }}
              _hover={{
                boxShadow: '0px 7px 11px rgba(0, 0, 0, 0.2)',
              }}
            >
              <IconBox h="30px" w="30px" transition={variantChange}>
                <DeleteIcon color={'#E53E3E'} />
              </IconBox>
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Xác nhận!</PopoverHeader>
            <PopoverBody>
              Bạn chắc chắn muốn xoá bản ghi này?
              <Button onClick={onDelete} ml={'4px'} colorScheme="red" size="xs">
                Đòng ý
              </Button>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Td>
    </Tr>
  );
};

export default TableRow;
