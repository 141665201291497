import TypeActions from 'redux/typeActions';

const getInvoice = (body, callback) => {
  return {
    type: TypeActions.GET_INVOICE_REQUEST,
    body,
    callback,
  };
};

const setInvoice = (data) => {
  return {
    type: TypeActions.GET_INVOICE_SUCCESS,
    data,
  };
};

const getInvoices = (body, callback) => {
  return {
    type: TypeActions.GET_INVOICES_REQUEST,
    body,
    callback,
  };
};

const getProducts = (query, callback) => {
  return {
    type: TypeActions.GET_PRODUCTS_REQUEST,
    query,
    callback,
  };
};

const resetInvoice = () => {
  return {
    type: TypeActions.RESET_INVOICE_REQUEST,
  };
};

const getPurchasePurposes = (query, callback) => {
  return {
    type: TypeActions.GET_PURCHASE_PURPOSES_REQUEST,
    query,
    callback,
  };
};

const createInvoice = (body, callback) => {
  return {
    type: TypeActions.CREATE_INVOICE_REQUEST,
    body,
    callback,
  };
};

const saveInvoices = (query, callback) => {
  return {
    type: TypeActions.SAVE_INVOICES_REQUEST,
    query,
    callback,
  };
};

const saveInvoice = (param, callback) => {
  return {
    type: TypeActions.SAVE_INVOICE_REQUEST,
    param,
    callback,
  };
};

const updateSaveInvoiceStatus = (param, body, callback) => {
  return {
    type: TypeActions.UPDATE_SAVE_INVOICE_STATUS_REQUEST,
    param,
    body,
    callback,
  };
};

const getStocks = (query, callback) => {
  return {
    type: TypeActions.GET_STOCKS_REQUEST,
    query,
    callback,
  };
};

const getStatistics = (query, callback) => {
  return {
    type: TypeActions.GET_STATISTICS_REQUEST,
    query,
    callback,
  };
};

const setQuery = (query) => {
  return {
    type: TypeActions.SET_QUERY_ORDER,
    query,
  };
};

const getInvoiceTypes = (query, callback) => {
  return {
    type: TypeActions.GET_INVOICE_TYPES_REQUEST,
    query,
    callback,
  };
};

export default {
  getInvoice,
  getInvoices,
  getProducts,
  createInvoice,
  getPurchasePurposes,
  saveInvoices,
  setInvoice,
  updateSaveInvoiceStatus,
  saveInvoice,
  getStocks,
  getStatistics,
  resetInvoice,
  setQuery,
  getInvoiceTypes,
};
