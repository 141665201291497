// Chakra imports
import {
  Portal,
  useDisclosure,
  Stack,
  Box,
  useColorMode,
  Image,
} from '@chakra-ui/react';
import Footer from 'components/Footer/Footer.js';

// Layout components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import React, { useState, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import { accountActions } from 'redux/actions';
import routes from 'routes.js';
import fac_logo from 'assets/img/fac_logo.png';
import MainPanel from '../components/Layout/MainPanel';
import PanelContainer from '../components/Layout/PanelContainer';
import PanelContent from '../components/Layout/PanelContent';
import Sidebar from 'components/Sidebar/Sidebar';

export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const dispatch = useDispatch();
  const [fixed, setFixed] = useState(false);
  const { colorMode } = useColorMode();
  // functions for changing the states from components
  const getActiveRoute = (routes) => {
    let activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].views);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  // This changes navbar state(fixed or not)
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].views);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          if (routes[i].secondaryNavbar) {
            return routes[i].secondaryNavbar;
          }
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  document.documentElement.dir = 'ltr';
  useEffect(() => {
    dispatch(
      accountActions.getCurrentAccount(
        localStorage.getItem('id'),
        queryString.stringify({ populate: 'roleId' })
      )
    );
  }, [window.location.href]);
  if (
    !localStorage.getItem('token') ||
    localStorage.getItem('token') === '' ||
    !localStorage.getItem('refreshtoken') ||
    localStorage.getItem('refreshtoken') === '' ||
    !localStorage.getItem('id') ||
    localStorage.getItem('id') === ''
  ) {
    // setRedirectDefault("/auth/login");
    return <Redirect to="/auth/login" />;
  }

  return (
    <Box>
      <Box
        minH="100vh"
        w="100%"
        position="absolute"
        bgImage={'none'}
        bg={'blue.500'}
        bgSize="cover"
        top="0"
      />
      <Sidebar routes={routes} display="none" {...rest} />
      <MainPanel w={'calc(100% - 275px)'}>
        <Portal>
          <AdminNavbar
            onOpen={onOpen}
            brandText={getActiveRoute(routes)}
            secondary={getActiveNavbar(routes)}
            fixed={fixed}
            {...rest}
          />
        </Portal>
        <PanelContent>
          <PanelContainer>
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/orders" />
            </Switch>
          </PanelContainer>
        </PanelContent>

        <Footer />
      </MainPanel>
    </Box>
  );
}
