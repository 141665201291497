import TypeActions from 'redux/typeActions';

export const getRoles = (query, callback) => {
  return {
    type: TypeActions.GET_ROLES_REQUEST,
    query,
    callback,
  };
};
export const getRole = (param, callback) => {
  return {
    type: TypeActions.GET_ROLE_REQUEST,
    param,
    callback,
  };
};
export const getRolePermission = (query, callback) => {
  return {
    type: TypeActions.GET_ROLE_PERMISSION_REQUEST,
    query,
    callback,
  };
};
export const createRole = (body, callback) => {
  return {
    type: TypeActions.CREATE_ROLE_REQUEST,
    body,
    callback,
  };
};
export const updateRole = (body, param, callback) => {
  return {
    type: TypeActions.UPDATE_ROLE_REQUEST,
    body,
    param,
    callback,
  };
};
export const deleteRole = (param, callback) => {
  return {
    type: TypeActions.DELETE_ROLE_REQUEST,
    param,
    callback,
  };
};

export default {
  getRoles,
  createRole,
  updateRole,
  deleteRole,
  getRolePermission,
  getRole,
};
