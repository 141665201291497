import TypeActions from 'redux/typeActions';

const initialState = {
  isCompanysGetting: false,
  companys: { results: [] },
  errors: {
    companys: '',
  },
};

const accountReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case TypeActions.GET_COMPANYS_REQUEST:
      return {
        ...state,
        companys: { results: [] },
        isCompanysGetting: false,
        errors: {
          ...state.errors,
          companys: '',
        },
      };
    case TypeActions.GET_COMPANYS_SUCCESS:
      return {
        ...state,
        companys: actions.data || { results: [] },
        isCompanysGetting: false,
        errors: {
          ...state.errors,
          companys: actions.error,
        },
      };
    case TypeActions.GET_COMPANYS_FAILED:
      return {
        ...state,
        isCompanysGetting: false,
        companys: { results: [] },
        errors: {
          ...state.errors,
          getAccount: '',
        },
      };

    default:
      return {
        ...state,
      };
  }
};
export default accountReducer;
