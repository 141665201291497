import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { InputForm } from 'components/InputForm/InputForm';
import _ from 'lodash';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { accountActions } from 'redux/actions';
import roleActions from 'redux/actions/roleActions';

export const CUUser = ({ isOpen, onClose, record, handleGetUsers }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const { isCreateAccount, isUpdateAccount } = useSelector(
    (state) => state.accountReducer
  );

  const { roles } = useSelector((state) => state.roleReducer);

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  function onSubmit(values) {
    const roleAdmin = (roles?.results ?? []).find(
      (result) => result.name === 'admin'
    );
    if (!roleAdmin)
      return toast({
        position: 'top-right',
        render: () => (
          <Box color="white" p={3} bg="red.500">
            Lỗi không tìm thâý phân quyền
          </Box>
        ),
      });
    const { name, email, password } = values;
    if (!!record?.id) {
      const body = { email, name };
      if (password) body.password = password;
      dispatch(
        accountActions.updateAccount(record.id, body, {
          success: () => {
            handleGetUsers();
            handleClose();
            // if (password) handleSetPassword(record.id, password);
            toast({
              position: 'top-right',
              render: () => (
                <Box color="white" p={3} bg="telegram.500">
                  Cập nhật người dùng thành công
                </Box>
              ),
            });
          },
          failed: (mess) => {
            toast({
              position: 'top-right',
              render: () => (
                <Box color="white" p={3} bg="red.500">
                  Cập nhật người dùng thất bại {mess}
                </Box>
              ),
            });
          },
        })
      );
      return;
    }
    dispatch(
      accountActions.createAccount(
        { email, name, password, roleId: roleAdmin.id },
        {
          success: (data) => {
            handleGetUsers();
            handleClose();
            // handleSetPassword(data.id, password);
            toast({
              position: 'top-right',
              render: () => (
                <Box color="white" p={3} bg="telegram.500">
                  Tạo người dùng thành công
                </Box>
              ),
            });
          },
          failed: (mess) => {
            toast({
              position: 'top-right',
              render: () => (
                <Box color="white" p={3} bg="red.500">
                  Tạo người dùng thất bại {mess}
                </Box>
              ),
            });
          },
        }
      )
    );
  }

  // const handleSetPassword = (userId, password) => {
  //   dispatch(
  //     accountActions.setPassword(
  //       { userId, password },
  //       {
  //         success: () => {},
  //         failed: (mess) => {},
  //       }
  //     )
  //   );
  // };

  const handleClose = () => {
    reset();
    onClose();
  };

  useEffect(() => {
    if (isOpen && !!record) {
      setValue('name', record.name);
      setValue('email', record.email);
      setValue('password', '');
      setValue('rePassword', '');
    }
  }, [isOpen, record]);

  useEffect(() => {
    dispatch(roleActions.getRoles(queryString.stringify({})));
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <ModalHeader>
            {!!record?.id ? 'Cập nhật' : 'Thêm mới'} tài khoản
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <InputForm
              code="name"
              error={errors.name}
              isRequired
              label="Tên tài khoản"
              placeholder="Nhập tên tài khoản"
              register={register('name', {
                required: 'Tên tài khoản không được để trống',
              })}
            />

            <InputForm
              code="email"
              error={errors.email}
              isRequired
              disabled={!_.isEmpty(record?.id)}
              label="Tên đăng nhập"
              placeholder="Nhập tên đăng nhập"
              register={register('email', {
                required: 'Tên đăng nhập không được để trống',
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: 'Vui lòng nhập đúng định dạng email',
                },
              })}
            />

            <InputForm
              code="newPassword"
              type="password"
              error={errors.password}
              isRequired={_.isEmpty(record?.id)}
              label="Mật khẩu"
              placeholder="Nhập mật khẩu"
              register={register('password', {
                validate: (val) => {
                  if (_.isEmpty(record?.id) && watch('password') === '')
                    return 'Mật khẩu không được để trống';
                },
              })}
            />

            <InputForm
              code="rePassword"
              type="password"
              error={errors.rePassword}
              isRequired={_.isEmpty(record?.id)}
              label="Xác nhận mật khẩu"
              placeholder="Nhập lại mật khẩu"
              register={register('rePassword', {
                validate: (val) => {
                  if (watch('password') !== '' || _.isEmpty(record?.id)) {
                    if (val === '')
                      return 'Xác nhận mật khẩu không được để trống';
                    if (watch('password') != val) {
                      return 'Mật khẩu không khớp';
                    }
                  }
                },
              })}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              isLoading={isCreateAccount || isUpdateAccount}
              type="submit"
              mr={3}
            >
              {!!record?.id ? 'Cập nhật' : 'Thêm mới'}
            </Button>
            <Button onClick={handleClose}>Đóng</Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};
