import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import TypeActions from 'redux/typeActions';
import ServiceURL from 'services/ServiceURL';
import { DELETE, GET, PATCH, POST } from 'services/ServiceBase';

export function* getOrderTemplates(data) {
  const url = ServiceURL.orderTemplates + '?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);

    if (res.message && !_.isEmpty(res?.message)) {
      yield put({
        type: TypeActions.GET_ORDER_TEMPLATES_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.GET_ORDER_TEMPLATES_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_ORDER_TEMPLATES_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* getOrderTemplate(data) {
  const url = ServiceURL.orderTemplates + '/' + data.param + '?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res?.message)) {
      yield put({
        type: TypeActions.GET_ORDER_TEMPLATE_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.GET_ORDER_TEMPLATE_SUCCESS,
        data: res.data,
      });
      callback.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_ORDER_TEMPLATE_FAILED,
      error: error?.response?.data?.message,
    });
    callback.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* createOrderTemplate(data) {
  const url = ServiceURL.orderTemplates;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res?.message)) {
      yield put({
        type: TypeActions.CREATE_ORDER_TEMPLATE_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.CREATE_ORDER_TEMPLATE_SUCCESS,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.CREATE_ORDER_TEMPLATE_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* updateOrderTemplate(data) {
  const url = ServiceURL.orderTemplates + '/' + data.param;
  const callback = data.callback;
  try {
    const res = yield call(PATCH, url, data.body);
    if (res.message && !_.isEmpty(res?.message)) {
      yield put({
        type: TypeActions.UPDATE_ORDER_TEMPLATE_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.UPDATE_ORDER_TEMPLATE_SUCCESS,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.UPDATE_ORDER_TEMPLATE_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* deleteOrderTemplate(data) {
  const url = ServiceURL.orderTemplates + '/' + data.param;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (res.message && !_.isEmpty(res?.message)) {
      yield put({
        type: TypeActions.DELETE_ORDER_TEMPLATE_FAILED,
        error: res?.error?.response?.data?.message,
      });
      callback.failed && callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.DELETE_ORDER_TEMPLATE_SUCCESS,
      });
      callback.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.DELETE_ORDER_TEMPLATE_FAILED,
      error: error?.response?.data?.message,
    });
    callback.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* getFieldsOrderTemplate(data) {
  const url = ServiceURL.orderTemplates + '/' + data.param + '?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res?.message)) {
      yield put({
        type: TypeActions.GET_FIELDS_ORDER_TEMPLATE_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.GET_FIELDS_ORDER_TEMPLATE_SUCCESS,
        data: res.data,
      });
      callback.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_FIELDS_ORDER_TEMPLATE_FAILED,
      error: error?.response?.data?.message,
    });
    callback.failed && callback.failed(error?.response?.data?.message);
  }
}
export default function* accountSaga() {
  yield takeLatest(TypeActions.GET_ORDER_TEMPLATES_REQUEST, getOrderTemplates);
  yield takeLatest(TypeActions.GET_ORDER_TEMPLATE_REQUEST, getOrderTemplate);
  yield takeLatest(
    TypeActions.CREATE_ORDER_TEMPLATE_REQUEST,
    createOrderTemplate
  );
  yield takeLatest(
    TypeActions.UPDATE_ORDER_TEMPLATE_REQUEST,
    updateOrderTemplate
  );
  yield takeLatest(
    TypeActions.DELETE_ORDER_TEMPLATE_REQUEST,
    deleteOrderTemplate
  );
  yield takeLatest(
    TypeActions.GET_FIELDS_ORDER_TEMPLATE_REQUEST,
    getFieldsOrderTemplate
  );
}
