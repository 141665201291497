export const BASE_URL = process.env.REACT_APP_BASE_URL;

const logIn = 'auth/login';
const logOut = 'auth/logout';
const forgotPassword = 'auth/forgot-password';
const resetPassword = 'auth/reset-password';
const changePassword = 'change-password';
const users = 'users';
const roles = 'roles';
const loadInvoice = 'invoices/loadInvoice';
const execProc = 'invoices/exec-proc';
const invoices = 'invoices';
const orderTemplates = 'invoiceTypes';
const saveinvoices = 'saveinvoices';
const companys = 'companys';

export default {
  BASE_URL,
  logIn,
  logOut,
  forgotPassword,
  resetPassword,
  changePassword,
  users,
  roles,
  loadInvoice,
  execProc,
  invoices,
  orderTemplates,
  saveinvoices,
  companys,
};
