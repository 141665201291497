// Chakra imports
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Flex,
  HStack,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  VStack,
  Input,
  Stack,
  Divider,
  Box,
  useToast,
  FormControl,
  FormErrorMessage,
  RadioGroup,
  Radio,
  Spinner,
} from '@chakra-ui/react';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
// Custom components
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import TableRow from './components/TableRow.jsx';
import { invoiceActions } from 'redux/actions';
import { arrSellColumns } from 'constants';
import CustomInputCalendar from 'components/CustomInputCalendar';
import { calculateResultSell, isNumeric } from 'common';
import moment from 'moment';
import { useDidUpdateEffect } from 'utils/HookUtils';
import {
  saleInvoiceDetailRequireFields,
  saleInvoiceRequireFields,
} from 'constants';

const initSaleInvoiceDetail = {
  StockAccountNumber: '156',
  VoucherID: null,
  ItemID: null,
  Description: '', // diễn giải nvarchar(255),
  StockID: 'B19B5F26-4A09-45D8-8345-8B458B0F7994', // kho vật tư uniqueidentifier,
  DebitAccount: 131, // TK phải thu/TK Nợ nvarchar(20),
  CreditAccount: 5111, // TK doanh thu/TK Có nvarchar(20),
  Unit: null, // đơn vị tính nvarchar(50),
  UnitConvert: null, // đơn vị tính chuển đổi nvarchar(50),
  OrderQuantity: 0, // decimal(22, 8),
  Quantity: 0, // số lượng decimal(22, 8),
  QuantityConvert: 0, // số lượng chuyển đổidecimal(22, 8),
  ActDiffQuantity: 0, // decimal(22, 8),
  ProductQuantity: 0, // decimal(22, 8),
  UnitPriceOC: 0, // đơn giá money,
  UnitPrice: 0, // đơn giá quy đổimoney,
  UnitPriceConvertOC: 0, // đơn giá chuyển đổimoney,
  UnitPriceConvert: 0, // đơn giá chuyển đổi quy đổi money,
  AmountOC: 0, // thành tiền money,
  Amount: 0, // thành tiền quy đổi money,
  DiscountRate: 0, // tỉ lệ chiết khấu decimal(22, 8),
  DiscountAmountOC: 0, // tiền chiết khấu money,
  DiscountAmount: 0, // tiền chiết khấu quy đổi money,
  DiscountAccount: null, // tài khoản chiết khấu nvarchar(20),
  VATAmountOC: 0, // tiền thuế money,
  VATAmount: 0, // tiền thuế chuyển đổi money,
  VATAccount: '33311', // tài khoản thuế nvarchar(20),
  InventoryAccount: null, // tk kho nvarchar(20),
  COGAccount: null, // tk giá vốn (nợ) nvarchar(20),
  OutwardPrice: 0, // đơn giá vốn money,
  OutwardAmount: 0, // tiền vốn quy đổi money,
  ConfrontingVoucherID: null, // VoucherID của chứng từ nhập đối trừ uniqueidentifier,
  ExpiryDate: null, // hạn dùng date,
  LotNo: null, // số lô nvarchar(255),
  Warranty: null, // hạn bảo hành nvarchar(255),
  AccountingObjectID: null, // đối tượng uniqueidentifier,
  ContractID: null, // hợp đồng uniqueidentifier,
  StatisticItemID: null, // mã thống kê uniqueidentifier,
  SortOrder: null, // Thứ tự sắp xếp các dòng chi tiếtint,
  EnvironmentTaxRate: 0, // decimal(18, 8),
  EnvironmentTaxAmountOC: 0, // money,
  EnvironmentTaxAmount: 0, // money,
  EnvironmentTaxAccount: null, // nvarchar(20),
  ResourcesTaxAmountOC: 0, // thu phí tài nguyên money,
  ResourcesTaxAmount: 0, // thuế phí tìa nguyên chuyển đổi money,
  ResourcesTaxAccount: null, // nvarchar(20),
  EnvironmentForestAmountOC: 0, // money,
  EnvironmentForestAmount: 0, // money,
  EnvironmentForestAccount: null, // nvarchar(20),
  OtherExpenseAmountOC: 0, // money,
  OtherExpenseAmount: 0, // money,
  OtherExpenseAccount: null, // nvarchar(20),
  SpecialConsumeTaxRate: 0, // decimal(22, 8),
  SpecialConsumeTaxAmountOC: 0, // money,
  SpecialConsumeTaxAmount: 0, // money,
  SpecialConsumeUnitPriceOC: 0, // money,
  SpecialConsumeUnitPrice: 0, // money,
  ConvertRate: 1, // tỉ lệ chuyển đổi  decimal(22, 8),
  UnitPriceAfterTaxOC: 0, // money,
  UnitPriceAfterTax: 0, // money,
  AmountAfterTaxOC: 0, // money,
  AmountAfterTax: 0, // money,
  DiscountAmountAfterTax: 0, // decimal(22, 8),
  DiscountAmountAfterTaxOC: 0, // decimal(22, 8),
  DepartmentID: null, // uniqueidentifier,
  CreditAccountingObjectID: null, // uniqueidentifier,
  ConfrontingVoucherDetailID: null, // Chi tiết chứng từ lien quan uniqueidentifier,
  ContractVoucherDetailID: null, // uniqueidentifier,
  JobID: null, // đối tượng tập hợp chi phí uniqueidentifier,
  ExpenseItemID: null, // khoản mục chi phí uniqueidentifier,
  SABonusPolicyDetailID: null, // uniqueidentifier,
  SAPolicyPriceDetailID: null, // uniqueidentifier,
  OutwardPriceConvert: 0, // đơn giá vốn chuyển đổi money,
  TransporterID: null, // người vận chuyển uniqueidentifier,
  ExportTaxRate: 0, // % thuế xk decimal(22, 8),
  ExportTaxAmountOC: 0, // tiền thuế xk money,
  ExportTaxAmount: 0, // tiền thuế xk chuyển đổi money,
  ExportTaxAccount: null, // tk thuế xk nvarchar(20),
  ColumnQuantity1: 0, // decimal(22, 8),
  ColumnQuantity2: 0, // decimal(22, 8),
  ColumnQuantity3: 0, // decimal(22, 8),
  ColumnAmount1: 0, // money,
  ColumnAmount2: 0, // money,
  PurchasePriceOC: 0, // đơn giá mua money,
  PurchasePrice: 0, // đơn giá mua chuyển đổi money,
  ColumnDate1: null, // date,
  ColumnDate2: null, // date,
  VATRate: 0, // decimal(18, 8),
  DiscountVATRate: 0, // decimal(18, 8),
  ColumnText1: null, // nvarchar(255),
  ColumnText2: null, // nvarchar(255),
  Select1: null, // uniqueidentifier,
  Select2: null, // uniqueidentifier,
  FromDate: null, // null date,
  Todate: null, // null date,
  VoucherDetailID: null, // id sale deitail uniqueidentifier OUTPUT
};

const initSaleInvoice = {
  // số phiếu nhập kho uniqueidentifier
  INVoucherNo: null,
  // bán hàng chưa thanh toán
  VoucherType: 200,
  AccountingObjectID: null,
  AccountingObjectName: null,
  AccountingObjectAddress: null,
  OriginalVoucherNo: null,
  INContactName: null,
  // diễn giải xuất kho
  INJournalMemo: 'Xuất bán hàng hoá',
  CABAVoucherNo: null,
  BankAccount: null,
  BankName: null,
  CABAContactName: null,
  CABAJournalMemo: null,
  DocumentIncluded: null,
  // khác - kiêm phiếu xuất
  SaleInvoiceAsOutward: 1,
  // khác - đã xuất hóa đơn
  BillPaid: 0,
  // loại hóa đơn - 1 hóa đơn GTGT
  InvType: 1,
  // Ký hiệu hóa đơn
  InvSeries: null,
  // số hóa đơn tài chính
  InvNo: null, // nvarchar(20),
  // diễn giải bán hàng nvarchar
  InvJournalMemo: 'Doanh thu bán hàng',
  // đại diện
  InvContactName: null,
  // mst khách hàng nvarchar(50)
  CompanyTaxCode: null,
  // loại tiền nvarchar 3
  CurrencyKindID: '',
  // tỉ giá hoán đổi decimal(22, 8)
  ExchangeRate: 1,
  // dk thanh toná uniqueidentifier
  PaymentTermID: null,
  // date hạn thanh toán
  DueDate: null,
  // nhân viên uniqueidentifier
  EmployeeID: null,
  // Tổng tiền hàng/ Tổng tiền thanh toán money
  TotalAmountOC: 0,
  // Tổng tiền hàng quy đổi/ Tổng tiền thanh toán quy đổi
  TotalAmount: 0,
  // tổng tiền ck
  TotalDiscountAmountOC: 0,
  // tổng tiền ck quy đổi
  TotalDiscountAmount: 0,
  // tổng tiền thuế
  TotalVATAmountOC: 0,
  // tổng tiền thuế quy đổi
  TotalVATAmount: 0,
  // tổng tiền vốn
  TotalOutwardAmount: 0,
  TotalEnvironmentTaxAmountOC: 0,
  TotalEnvironmentTaxAmount: 0,
  TotalResourcesTaxAmountOC: 0,
  TotalResourcesTaxAmount: 0,
  TotalOtherExpenseAmountOC: 0,
  TotalOtherExpenseAmount: 0,
  TotalEnvironmentForestAmountOC: 0,
  TotalEnvironmentForestAmount: 0,
  // trạng thái ghi sổ / chưa ghi sổ
  IsPosted: 0,
  // id loại hóa đơn - mẫu số hóa đơn
  InvoiceTypeID: null,
  OutwardVoucherID: null,
  // decimal(22, 8),
  CommisionRate: 0,
  CommisionAmountOC: 0,
  CommisionAmount: 0,
  // số bảng kê nvarchar 20
  ListNo: null,
  // ngày bảng kê date
  ListDate: null,
  // tên mặt hàng chung
  ListCommonNameInventory: null,
  // in kèm bảng kê bit
  IsAttachList: 0,
  EditVersion: null,
  PostVersion: null,
  // hiển thị đơn vị chuyển đổi bit
  IsShowUnitConvert: 0,
  // nvarchar(100)
  CreatedBy: null,
  ModifiedBy: null,
  BranchID: null,
  // ngày chứng từ
  VoucherDate: new Date(),
  // ngày ghi sổ
  PostedDate: null,
  IsServiceInvoice: false,
  // diễn giải thuế
  VATDescription: '',
  // mã nhóm hàng hóa dịch vụ
  PurchasePurposeID: null,
  // tiền thuế xuất khẩu
  TotalExportTaxAmountOC: 0,
  // tiền thuế xuất khẩu chuyển đổi
  TotalExportTaxAmount: 0,
  // xuất chênh lệch bit
  IsDiffOutward: 0,
  IsNotUpdateOutward: 0,
  // int
  InvoiceStatus: null,
  // int
  SignatureStatus: null,
  // ht thanh toán
  PaymentMethodName: '',
  EInvoiceID: null,
  Synchonized: null,
  TransferMethod: null,
  CopiedFromVoucherID: null,
  VoucherKind: null,
  VoucherID: null,
};

function OrderSellAdd({ location }) {
  const toast = useToast();
  const history = useHistory();
  const dispatch = useDispatch();

  const isApprove = location?.state?.isApprove ?? false;
  const isDetail = location?.state?.isDetail ?? false;
  const record = location?.state?.row;
  const {
    isGettingInvoices,
    invoice,
    products,
    purchasePurposes,
    isCreatingInvoice,
    isUpdatingInvoiceStatus,
    isGettingProducts,
    isGettingPurchasePurposes,
    isGettingStocks,
    stocks,
    invoiceTypes,
    isGettingInvoiceTypes,
    isGettingStatistics,
    statistics,
  } = useSelector((state) => state.invoiceReducer);
  const recordsets = (products?.recordsets ?? []).flat(1);
  const invoiceTypesRecordsets = (invoiceTypes?.recordsets ?? []).flat(1);
  const purchasePurposeRecordsets = (purchasePurposes?.recordsets ?? []).flat(
    1
  );

  const [saleInvoice, setSaleInvoice] = useState(initSaleInvoice);
  const [orderType, setOrderType] = useState(-1);
  const [saleInvoiceDetails, setSaleInvoiceDetails] = useState([]);
  const [saleInvoiceDetails2, setSaleInvoiceDetails2] = useState([]);
  const [isSwaping, setIsSwaping] = useState(false);
  const [tab, setTab] = useState(0);
  const [invoiceTypeValue, setInvoiceTypeValue] = useState(null);

  // const saleInvoiceDetails = invoice?.saleInvoiceDetails ?? [];
  const [fileUpload, setFileUpload] = useState({
    file: '',
    name: '',
  });

  // const [invoiceProvider, setInvoiceProvider] = useState({
  //   label: 'BKAV',
  //   value: 'bkav',
  // });

  function checkSaleInvoiceRequireFields(data) {
    const messages = [];
    Object.keys(saleInvoiceRequireFields).forEach((key) => {
      if (!data || !data[key] || data[key] === null) {
        messages.push(saleInvoiceRequireFields[key]);
      }
    });
    if (messages.length > 0) {
      toast({
        position: 'top-right',
        title: 'Thông báo',
        render: () => (
          <VStack
            style={{
              maxHeight: '80vh',
              overflowY: 'scroll',
              justifyContent: 'flex-start',
            }}
            color="white"
            p={3}
            bg="red.500"
          >
            {messages.map((message) => (
              <span style={{ text: 'white', fontSize: 12 }}>
                {message.message} không hợp lệ!
              </span>
            ))}
          </VStack>
        ),
      });
      return false;
    }
    return true;
  }

  function checkSaleInvoiceDetailsRequireFields(data) {
    const messages = [];
    Object.keys(saleInvoiceDetailRequireFields).forEach((key) => {
      data.forEach((r, i) => {
        if (_.isNil(r[key]) || r[key] === '') {
          messages.push({
            ...saleInvoiceDetailRequireFields[key],
            index: i,
          });
        }
      });
    });
    if (messages.length > 0) {
      toast({
        position: 'top-right',
        render: () => (
          <VStack
            style={{
              maxHeight: '80vh',
              overflowY: 'scroll',
              justifyContent: 'flex-start',
            }}
            color="white"
            p={3}
            bg="red.500"
          >
            {messages.map((message) => (
              <span style={{ text: 'white', fontSize: 12 }}>
                Dòng {message.index + 1}: {message.message} không hợp lệ!
              </span>
            ))}
          </VStack>
        ),
      });
      return false;
    }
    return true;
  }

  const onSubmit = (isOut = false) => {
    const ret = calculateResultSell({
      saleInvoice,
      saleInvoiceDetails,
    });

    const tmp = _.cloneDeep(ret.saleInvoiceDetails);

    tmp.forEach((item) => {
      Object.keys(item ?? {}).forEach(function (key) {
        if (
          key === 'x1' ||
          key === 'x2' ||
          key === 'x3' ||
          key === 'x4' ||
          key === 'x5' ||
          key === 'label' ||
          key === 'value'
        ) {
          delete item[key];
        }
      });
    });
    const check1 = checkSaleInvoiceRequireFields(ret.saleInvoice);
    const check2 = checkSaleInvoiceDetailsRequireFields(tmp);
    if (!check1 || !check2) {
      return;
    }

    const body = {
      saleInvoice: {
        ...ret.saleInvoice,
        PostedDate: !!ret.saleInvoice?.PostedDate
          ? moment(ret.saleInvoice.PostedDate).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
        VoucherDate: !!ret.saleInvoice?.VoucherDate
          ? moment(ret.saleInvoice.VoucherDate).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
        DueDate: !!ret.saleInvoice?.DueDate
          ? moment(ret.saleInvoice.DueDate).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
      },
      saleInvoiceDetails: tmp,
    };

    if (isApprove) {
      dispatch(
        invoiceActions.updateSaveInvoiceStatus(
          record.id,
          Object.assign(body, { saveInvoiceStatus: 1 }),
          {
            success: () => {
              if (isOut) {
                setTimeout(() => {
                  history.goBack();
                }, 2000);
              }
              toast({
                position: 'top-right',
                render: () => (
                  <Box color="white" p={3} bg="telegram.500">
                    Duyệt invoice thành công
                  </Box>
                ),
              });
            },
            failed: (mess) => {
              toast({
                position: 'top-right',
                render: () => (
                  <Box color="white" p={3} bg="red.500">
                    Duyệt invoice lỗi!. Lỗi: {mess}
                  </Box>
                ),
              });
            },
          }
        )
      );
      return;
    }
    dispatch(
      invoiceActions.createInvoice(
        { ...body, isSaleInvoice: true },
        {
          success: () => {
            if (isOut) {
              setTimeout(() => {
                history.goBack();
              }, 2000);
            }
            toast({
              position: 'top-right',
              render: () => (
                <Box color="white" p={3} bg="telegram.500">
                  Tạo invoice thành công
                </Box>
              ),
            });
          },
          failed: (mess) => {
            toast({
              position: 'top-right',
              render: () => (
                <Box color="white" p={3} bg="red.500">
                  Tạo invoice lỗi!. Lỗi: {mess}
                </Box>
              ),
            });
          },
        }
      )
    );
  };

  // const handleChangeFile = (e) => {
  //   if (_.isEmpty(e)) return;
  //   const arrNameFile = e.target.files[0].name.split('.');
  //   if (arrNameFile[arrNameFile.length - 1] === 'xml') {
  //     setFileUpload({
  //       name: arrNameFile,
  //       file: e.target.files[0],
  //     });
  //     const body = {
  //       xmlFile: e.target.files[0],
  //       invoiceProvider: invoiceProvider.value,
  //     };
  //     dispatch(
  //       invoiceActions.getInvoice(body, {
  //         success: (data) => {},
  //         failed: (mess) => {
  //           toast({
  //             position: 'top-right',
  //             render: () => (
  //               <Box color="white" p={3} bg="red.500">
  //                 Lấy dữ liệu lỗi!. Lỗi: {mess}
  //               </Box>
  //             ),
  //           });
  //         },
  //       })
  //     );
  //   } else {
  //     toast({
  //       position: 'top-right',
  //       render: () => (
  //         <Box color="white" p={3} bg="orange.500">
  //           Vui lòng chọn đúng định dạng file xml!
  //         </Box>
  //       ),
  //     });
  //   }
  // };

  // const pushSaleInvoiceDetail = () => {
  //   setSaleInvoiceDetails((prev) => [...prev, initSaleInvoiceDetail]);
  // };

  const handleSetSaleInvoiceDetails = ({
    index = -1,
    name = '',
    value = '',
  }) => {
    if (index === -1) return;

    try {
      if (name === 'selectProduct') {
        setSaleInvoiceDetails([
          ...saleInvoiceDetails.slice(0, index),
          !value ? initSaleInvoiceDetail : value,
          ...saleInvoiceDetails.slice(index + 1),
        ]);
      } else if (name === 'StockID') {
        const tmp = _.cloneDeep(saleInvoiceDetails[index]);
        tmp[name] = value;
        const stockRecordsets = (stocks?.recordsets ?? []).flat(1);
        tmp.StockAccountNumber =
          stockRecordsets.find((item) => item.StockID === value)
            ?.StockAccountNumber || '';
        setSaleInvoiceDetails([
          ...saleInvoiceDetails.slice(0, index),
          tmp,
          ...saleInvoiceDetails.slice(index + 1),
        ]);
      } else {
        const tmp = _.cloneDeep(saleInvoiceDetails[index]);
        tmp[name] = value;
        // const Amount =
        //   Number(tmp?.Quantity ?? '0') * Number(tmp?.UnitPrice ?? '0');
        // tmp.Amount = Amount;

        switch (name) {
          case 'Quantity':
            tmp.OutwardAmount = tmp.OutwardPrice * Number(tmp?.Quantity || 0);
            tmp.OutwardAmountOC = tmp.OutwardAmount / saleInvoice.ExchangeRate;
          case 'UnitPrice':
            tmp.Amount =
              Number(tmp?.UnitPrice ?? 0) * Number(tmp?.Quantity ?? '0');
            tmp.AmountOC = tmp.Amount / saleInvoice.ExchangeRate;
            break;
          case 'VATRate':
            tmp.VATAmount = (Number(value) * tmp.AmountOC) / 100;
            tmp.VATAmountOC = VATAccount / saleInvoice.ExchangeRate;
            break;
        }
        setSaleInvoiceDetails([
          ...saleInvoiceDetails.slice(0, index),
          tmp,
          ...saleInvoiceDetails.slice(index + 1),
        ]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (products?.recordsets === undefined && !isGettingProducts) {
      dispatch(
        invoiceActions.getProducts(
          queryString.stringify({
            procName: 'Proc_SelectItem',
          })
        )
      );
    }
    if (
      purchasePurposes?.recordsets === undefined &&
      !isGettingPurchasePurposes
    ) {
      dispatch(
        invoiceActions.getPurchasePurposes(
          queryString.stringify({
            procName: 'Proc_SelectPurchasePurpose',
          })
        )
      );
    }

    if (stocks?.recordsets === undefined && !isGettingStocks) {
      dispatch(
        invoiceActions.getStocks(
          queryString.stringify({
            procName: 'Proc_SelectStock',
          })
        )
      );
    }

    if (statistics?.recordsets === undefined && !isGettingStatistics) {
      dispatch(
        invoiceActions.getStatistics(
          queryString.stringify({
            procName: 'Proc_SelectStatisticItem',
          })
        )
      );
    }

    if (invoiceTypes?.recordsets === undefined && !isGettingInvoiceTypes) {
      dispatch(
        invoiceActions.getInvoiceTypes(
          queryString.stringify({
            procName: 'Proc_SelectInvoiceType',
          })
        )
      );
    }
  }, [
    isGettingProducts,
    isGettingPurchasePurposes,
    isGettingStocks,
    isGettingStatistics,
    isGettingInvoiceTypes,
  ]);

  useDidUpdateEffect(() => {
    if (!invoice?.saleInvoice?.AccountingObjectCode && !!fileUpload?.name)
      toast({
        position: 'top-right',
        render: () => (
          <Box color="white" p={3} bg="red.500">
            Mã NCC không chưa xác định
          </Box>
        ),
      });

    setSaleInvoice(
      invoice?.saleInvoice
        ? {
            ...invoice.saleInvoice,
            // VoucherType: invoice?.VoucherType
            //   ? parseInt(invoice.VoucherType)
            //   : null,
            INJournalMemo: 'Xuất bán hàng hoá',
            CABAJournalMemo: 'Xuất bán hàng hoá',
            CompanyTaxCode: invoice?.saleInvoice?.AccountingObjectCode,
          }
        : initSaleInvoice
    );
    // if ([101, 87, 77].includes(invoice?.saleInvoice?.VoucherType)) {
    //   setOrderType(1);
    // }
    const exchangeRate = invoice?.saleInvoice?.ExchangeRate || 1;
    const p = (invoice?.saleInvoiceDetails ?? []).map((data) => {
      const ps = recordsets.filter(
        (record) =>
          (record?.ItemName ?? '').includes(data?.Description ?? '') ||
          (data?.Description ?? '').includes(record?.ItemName ?? '')
      );

      let product = ps.find((p) => p.ItemName.includes(data.UnitPrice));
      if (!product) {
        product = recordsets.find(
          (record) =>
            (record?.ItemName ?? '').includes(data?.Description ?? '') ||
            (data?.Description ?? '').includes(record?.ItemName ?? '')
        );
      }

      if (!product)
        return {
          ...data,
          ItemName: data?.Description ?? '',
          PurchasePurposeID: '68F05655-1AFC-45AB-834A-37FAA500766C',
          PurchasePurposeCode: '1',
        };
      const purchasePurpose = purchasePurposeRecordsets.find(
        (record) => record.PurchasePurposeID === product.PurchasePurposeID
      );
      const OutwardPrice = Number(product?.PurchasePrice || 0);
      const OutwardAmountOC = OutwardPrice * Number(data?.Quantity || 0);
      return {
        ...initSaleInvoiceDetail,
        ...data,
        ...product,
        label: `(${product?.ItemCode ?? ''})${product?.ItemName ?? ''}`,
        value: product?.ItemID ?? '',
        OutwardPrice,
        OutwardPriceConvert: OutwardPrice,
        OutwardPriceOC: OutwardPrice / exchangeRate,
        OutwardPriceConvert: OutwardPrice / exchangeRate,
        Description: product?.ItemName ?? '',
        VATAccount: product?.VATAccount ?? '33311',
        PurchasePurposeID: purchasePurpose
          ? purchasePurpose.PurchasePurposeID
          : '68F05655-1AFC-45AB-834A-37FAA500766C',
        PurchasePurposeCode: purchasePurpose
          ? purchasePurpose.PurchasePurposeCode
          : '1',
        OutwardAmountOC,
        OutwardAmount: OutwardAmountOC * exchangeRate,
        AccountingObjectCode:
          invoice?.saleInvoice?.AccountingObjectCode ?? null,
        CompanyTaxCode: invoice?.saleInvoice?.AccountingObjectCode ?? null,
        AccountingObjectAddress:
          invoice?.saleInvoice?.AccountingObjectAddress ?? null,
        VATDescription: invoice?.saleInvoice?.VATDescription ?? null,
      };
    });
    setSaleInvoiceDetails(p);

    if (p.length > 0) {
      const obj = p.reduce(
        (prev, curr) => {
          const VATAmountOC =
            prev.VATAmountOC + (curr.VATRate * curr.AmountOC) / 100;

          const amountOC =
            Number(curr?.UnitPriceOC || 0) * Number(curr?.Quantity || 0);
          const AmountOC = prev.AmountOC + amountOC;
          const OutwardPrice =
            prev.OutwardPrice + Number(curr?.PurchasePrice || 0);
          const amountOutwardAmountOC =
            (Number(curr?.PurchasePrice || 0) * Number(curr?.Quantity || 0)) /
            exchangeRate;
          const OutwardAmountOC = prev.OutwardAmountOC + amountOutwardAmountOC;
          const OutwardPriceConvertOC =
            prev.OutwardPriceConvertOC + curr.OutwardPriceConvertOC;
          const TurnOverAmountOC = prev.TurnOverAmountOC + amountOC;

          return {
            VATAmountOC,
            AmountOC,
            OutwardPrice,
            OutwardAmountOC,
            OutwardPriceConvertOC,
            TurnOverAmountOC,
          };
        },
        {
          VATAmountOC: 0,
          AmountOC: 0,
          OutwardPrice: 0,
          OutwardAmountOC: 0,
          OutwardPriceConvertOC: 0,
          TurnOverAmountOC: 0,
        }
      );

      obj.Amount = obj.AmountOC * exchangeRate;
      obj.UnitPriceOC = obj.AmountOC;
      obj.UnitPrice = obj.UnitPriceOC * exchangeRate;
      obj.OutwardPriceOC = obj.OutwardPrice / exchangeRate;
      obj.OutwardAmount = obj.OutwardAmountOC * exchangeRate;
      obj.OutwardPriceConvertOC = obj.OutwardPriceOC;
      obj.OutwardPriceConvert = obj.OutwardPrice;
      obj.TurnOverAmount = obj.TurnOverAmountOC * exchangeRate;
      obj.TotalAmountOC = obj.AmountOC;
      obj.TotalAmount = obj.AmountOC * exchangeRate;
      obj.VATAmount = obj.VATAmountOC * exchangeRate;
      obj.UnitPrice = obj.UnitPriceOC * exchangeRate;
      const product = recordsets.find(
        (record) =>
          (record?.ItemName ?? '')
            .toLowerCase()
            .includes('DVMN'.toLowerCase()) ||
          'Dịch vụ mua ngoài'
            .toLowerCase()
            .includes((record?.ItemName ?? '').toLowerCase())
      );

      const p2 = [
        Object.assign(
          {
            ...p[0],
            Quantity: 1,
            ExpenseItemID: 'A3721A41-EE69-4105-8146-7B9F1287C022',
          },
          product
            ? {
                ...product,
                ...obj,
                label: `(${product?.ItemCode ?? ''})${product?.ItemName ?? ''}`,
                value: product?.ItemID ?? '',
                Description: product?.ItemName ?? '',
                CreditAccount: 5113,
                VATAccount: product?.VATAccount ?? '33311',
              }
            : {}
        ),
      ];
      setSaleInvoiceDetails2(p2);
    }
  }, [invoice, products]);

  useDidUpdateEffect(() => {
    if (invoice?.saleInvoice?.InvSeries && !invoiceTypeValue) {
      const iTTV = invoiceTypesRecordsets.find((item) => {
        return item.InvoiceTypeName === invoice?.saleInvoice?.InvSeries;
      });
      if (iTTV) {
        setInvoiceTypeValue({
          label: iTTV.InvoiceTypeName,
          value: iTTV.InvoiceTypeID,
        });
        setSaleInvoice((prev) => ({
          ...prev,
          InvSeries: iTTV.InvoiceTypeName,
          InvoiceTypeID: iTTV.InvoiceTypeID,
        }));
      }
    }
  }, [invoice, invoiceTypesRecordsets]);

  function swapSaleInvoiceDetails() {
    setIsSwaping((prev) => !prev);
    const tmp = _.cloneDeep(saleInvoiceDetails);
    setSaleInvoiceDetails(saleInvoiceDetails2);
    setSaleInvoiceDetails2(tmp);
    setTimeout(() => {
      setIsSwaping((prev) => !prev);
    }, 500);
  }

  useEffect(() => {
    if (!!record) {
      dispatch(invoiceActions.setInvoice(record));
    } else {
      dispatch(invoiceActions.setInvoice({}));
    }
  }, [record]);

  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  const renderTable = useMemo(() => {
    // if (_.isEmpty(saleInvoiceDetails))
    //   return (
    //     <IconButton
    //       colorScheme="blue"
    //       onClick={pushSaleInvoiceDetail}
    //       icon={<AddIcon />}
    //     />
    //   );

    return (
      <Box style={{ overflowX: 'scroll' }}>
        <Table style={{}} variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
              {arrSellColumns[tab].map((item, index) => (
                <Th
                  key={index}
                  borderColor={borderColor}
                  fontSize={item?.headerStyle ?? [10, 10, 12, 12]}
                  px={'5px'}
                  py={'8px'}
                  color="#054169"
                  className={item?.className ?? ''}
                  style={item?.headerStyle ?? {}}
                >
                  <span
                    className={
                      !!saleInvoiceDetailRequireFields[item.value]
                        ? 'required'
                        : ''
                    }
                  >
                    {item.label}
                  </span>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {!isSwaping
              ? saleInvoiceDetails.map((item, index, arr) => {
                  return (
                    <TableRow
                      data={item ?? initSaleInvoiceDetail}
                      isLast={index === arr.length - 1 ? true : false}
                      key={index}
                      indexP={index}
                      arrColumns={arrSellColumns}
                      tab={tab}
                      AccountingObjectCode={saleInvoice.AccountingObjectCode}
                      AccountingObjectAddress={
                        saleInvoice.AccountingObjectAddress
                      }
                      VATDescription={saleInvoice.VATDescription}
                      isDetail={isDetail}
                      handleSetSaleInvoiceDetails={handleSetSaleInvoiceDetails}
                      ExchangeRate={saleInvoice?.ExchangeRate || 1}
                    />
                  );
                })
              : null}
          </Tbody>
        </Table>
        {/* <IconButton
          onClick={pushSaleInvoiceDetail}
          mt={3}
          colorScheme="blue"
          icon={<AddIcon />}
        /> */}
      </Box>
    );
  }, [tab, isSwaping, isGettingInvoices, saleInvoiceDetails]);

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
        <form onSubmit={onSubmit}>
          <Card pb="0px">
            <CardHeader p="6px 0px 22px 0px">
              <VStack mb={3}>
                <HStack width={'100%'} justifyContent={'space-between'}>
                  <Text
                    w={'100%'}
                    fontSize={16}
                    color={'#054169'}
                    fontWeight="bold"
                  >
                    TẬP TIN HÓA ĐƠN
                  </Text>
                  <HStack>
                    <Button
                      onClick={() => {
                        history.goBack();
                      }}
                      fontSize={[10, 12, 14, 16]}
                      colorScheme="gray"
                      textTransform={'uppercase'}
                    >
                      Quay lại
                    </Button>
                    {!isDetail && (
                      <Button
                        disabled={isCreatingInvoice || isUpdatingInvoiceStatus}
                        isLoading={isCreatingInvoice || isUpdatingInvoiceStatus}
                        onClick={() => onSubmit(true)}
                        fontSize={[10, 12, 14, 16]}
                        colorScheme="orange"
                        textTransform={'uppercase'}
                      >
                        Lưu và thoát
                      </Button>
                    )}
                    {!isApprove && !isDetail && (
                      <Button
                        disabled={isCreatingInvoice || isUpdatingInvoiceStatus}
                        isLoading={isCreatingInvoice || isUpdatingInvoiceStatus}
                        onClick={() => onSubmit(false)}
                        fontSize={[10, 12, 14, 16]}
                        colorScheme="orange"
                        textTransform={'uppercase'}
                      >
                        Lưu và tiếp tục
                      </Button>
                    )}
                  </HStack>
                </HStack>
                {/* <Stack
                  justifyContent={[
                    'flex-start',
                    'flex-start',
                    'center',
                    'center',
                  ]}
                  flexDir={['column', 'column', 'column', 'row']}
                  w={'100%'}
                >
                  <HStack
                    mx={[0, 0, 0, 3]}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                  >
                    <Text
                      color={'#054169'}
                      fontSize={14}
                      fontWeight={'400'}
                      as="span"
                    >
                      Chọn nhà cung cấp hóa đơn
                    </Text>
                    <Box minW={250}>
                      <Select
                        placeholder="Lựa chọn"
                        value={isApprove ? null : invoiceProvider}
                        isClearable={true}
                        onChange={(e) => {
                          setInvoiceProvider(e);
                        }}
                        isDisabled={isApprove || isDetail}
                        options={[
                          { label: 'BKAV', value: 'Bkav' },
                          { label: 'EASYVOICE', value: 'EasyVoice' },
                          { label: 'MEINVOICE', value: 'MeinVoice' },
                          { label: 'VNPT', value: 'Vnpt' },
                          { label: 'VIETTEL', value: 'Viettel' },
                        ]}
                        onInputChange={(value) => {
                          // setRoleSearch(value);
                        }}
                        onFocus={() => {}}
                      />
                    </Box>
                  </HStack>

                  <HStack
                    mx={3}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                  >
                    <Text
                      color={'#054169'}
                      fontSize={14}
                      fontWeight={'400'}
                      as="span"
                    >
                      Upload tập tin hóa đơn{'   '}
                      <Text
                        opacity={0.7}
                        color={'#1E1E1E'}
                        fontSize={14}
                        fontWeight={'400'}
                        as="span"
                      >
                        {fileUpload.name === ''
                          ? 'Chưa có tập tin được chọn'
                          : fileUpload.name}
                      </Text>
                    </Text>
                    {!isGettingInvoices && (
                      <input
                        className="custom-file-input-css"
                        id="customFileLang"
                        lang="en"
                        type="file"
                        accept=".xml"
                        disabled={isApprove || isDetail}
                        onChange={handleChangeFile}
                        style={{ display: 'none' }}
                      />
                    )}

                    <label
                      style={{
                        background: '#3182ce',
                        borderRadius: 6,
                        fontSize: 12,
                        color: 'white',
                        padding: '5px 40px',
                        lineHeight: 1.2,
                        fontWeight: '700',
                      }}
                      htmlFor="customFileLang"
                    >
                      Chọn file (XML)
                    </label>
                  </HStack>
                </Stack> */}
              </VStack>
              <Divider />
            </CardHeader>
            <CardBody mb={5}>
              <Stack flexDir={['column', 'column', 'column', 'row']}>
                <VStack
                  pr={[0, 0, 0, 3]}
                  borderRight={['none', 'none', 'none', '1px solid #ccc']}
                  justifyContent={'space-between'}
                  spacing={3}
                  flex={7}
                >
                  <Text
                    w={'100%'}
                    fontSize={16}
                    color={'#054169'}
                    fontWeight="bold"
                  >
                    Thông tin khách hàng
                  </Text>

                  <Stack
                    alignItems={['', '', 'center', 'center']}
                    flexDir={['column', 'column', 'row', 'row']}
                    w={'100%'}
                  >
                    <Box w={170}>Khách hàng</Box>
                    <Box px={[0, 0, 1, 1]}>
                      <FormControl
                        isInvalid={
                          (saleInvoice?.AccountingObjectCode ?? '') === '' &&
                          !!fileUpload?.name
                        }
                      >
                        <Input
                          maxW={150}
                          rounded={6}
                          placeholder="Mã NCC"
                          size={'md'}
                          id={'AccountingObjectCode'}
                          name={'AccountingObjectCode'}
                          value={saleInvoice?.AccountingObjectCode ?? ''}
                          disabled={isDetail}
                          onChange={(e) => {
                            setSaleInvoice({
                              ...saleInvoice,
                              AccountingObjectCode: e.target.value,
                            });
                          }}
                          // disabled={true}
                        />
                        <FormErrorMessage>
                          {(saleInvoice?.AccountingObjectCode ?? '') === '' &&
                            !!fileUpload?.name &&
                            'Mã NCC không được để trống!'}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                    <Box px={[0, 0, 1, 1]} flex={1}>
                      <Input
                        rounded={6}
                        placeholder="Nhập tên NCC"
                        size={'md'}
                        id={'AccountingObjectName'}
                        name={'AccountingObjectName'}
                        value={saleInvoice?.AccountingObjectName ?? ''}
                        disabled={isDetail}
                        onChange={(e) => {
                          setSaleInvoice({
                            ...saleInvoice,
                            AccountingObjectName: e.target.value,
                          });
                        }}
                      />
                    </Box>
                  </Stack>
                  <Stack
                    alignItems={['', '', 'center', 'center']}
                    flexDir={['column', 'column', 'row', 'row']}
                    w={'100%'}
                  >
                    <Box w={170}>Địa chỉ</Box>
                    <Box px={[0, 0, 1, 1]} flex={1}>
                      <Input
                        rounded={6}
                        placeholder="Nhập địa chỉ"
                        size={'md'}
                        id={'AccountingObjectAddress'}
                        name={'AccountingObjectAddress'}
                        value={saleInvoice?.AccountingObjectAddress ?? ''}
                        disabled={isDetail}
                        onChange={(e) => {
                          setSaleInvoice({
                            ...saleInvoice,
                            AccountingObjectAddress: e.target.value,
                          });
                        }}
                      />
                    </Box>
                  </Stack>
                  <Stack
                    alignItems={['', '', 'center', 'center']}
                    flexDir={['column', 'column', 'row', 'row']}
                    w={'100%'}
                  >
                    <Box w={170}>Mã số thuế</Box>
                    <Box px={[0, 0, 1, 1]}>
                      <Input
                        maxW={200}
                        rounded={6}
                        placeholder="Nhập mã số thuế"
                        size="md"
                        value={saleInvoice?.CompanyTaxCode ?? ''}
                        disabled={isDetail}
                        onChange={(e) => {
                          if (!isNumeric(e.target.value)) return;
                          setSaleInvoice({
                            ...saleInvoice,
                            CompanyTaxCode: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <Box
                      pr={2}
                      w={170}
                      textAlign={['left', 'left', 'right', 'right']}
                    >
                      Người mua
                    </Box>
                    <Box flex={1}>
                      <Input
                        rounded={6}
                        placeholder="Nhập người liên hệ"
                        size={'md'}
                        value={saleInvoice?.INContactName ?? ''}
                        disabled={isDetail}
                        onChange={(e) => {
                          setSaleInvoice({
                            ...saleInvoice,
                            INContactName: e.target.value,
                          });
                        }}
                      />
                    </Box>
                  </Stack>
                  <Stack
                    alignItems={['', '', 'center', 'center']}
                    flexDir={['column', 'column', 'row', 'row']}
                    w={'100%'}
                  >
                    <Box w={170}>Diễn giải bán hàng</Box>
                    <Box px={[0, 0, 1, 1]} flex={1}>
                      <Input
                        rounded={6}
                        placeholder="Nhập diễn giải"
                        size={'md'}
                        value={
                          saleInvoice?.InvJournalMemo ?? 'Doanh thu bán hàng'
                        }
                        disabled={isDetail}
                        onChange={(e) => {
                          setSaleInvoice({
                            ...saleInvoice,
                            InvJournalMemo: e.target.value,
                          });
                        }}
                      />
                    </Box>
                  </Stack>
                  <Stack
                    alignItems={[
                      'flex-start',
                      'flex-start',
                      'center',
                      'center',
                    ]}
                    flexDir={['column', 'column', 'row', 'row']}
                    w={'100%'}
                  >
                    <Box w={170}>Nv bán hàng</Box>
                    <Box px={[0, 0, 1, 1]}>
                      <Input
                        maxW={200}
                        rounded={6}
                        placeholder="Nhập nhân viên mua"
                        size="md"
                        value={saleInvoice?.EmployeeID ?? ''}
                        disabled={isDetail}
                        onChange={(e) => {
                          setSaleInvoice({
                            ...saleInvoice,
                            EmployeeID: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <Stack
                      px={[0, 0, 1, 1]}
                      alignItems={[
                        'flex-start',
                        'flex-start',
                        'center',
                        'center',
                      ]}
                      flexDir={['column', 'column', 'row', 'row']}
                      flex={1}
                    >
                      <Box
                        pr={2}
                        w={170}
                        textAlign={['left', 'left', 'right', 'right']}
                      >
                        Kèm theo
                      </Box>
                      <HStack>
                        <Input
                          value={saleInvoice?.DocumentIncluded ?? ''}
                          disabled={isDetail}
                          onChange={(e) => {
                            setSaleInvoice({
                              ...saleInvoice,
                              DocumentIncluded: e.target.value,
                            });
                          }}
                          rounded={6}
                          placeholder="Nhập chứng từ gốc"
                          size={'md'}
                        />
                        <Text pl={2} as={'span'} w={200}>
                          chứng từ gốc
                        </Text>
                      </HStack>
                    </Stack>
                  </Stack>
                  <Stack
                    alignItems={['', '', 'center', 'center']}
                    flexDir={['column', 'column', 'row', 'row']}
                    w={'100%'}
                  >
                    <Box w={170}>
                      <span className="required">Loại hoá đơn</span>
                    </Box>
                    <Box px={[0, 0, 1, 1]}>
                      <RadioGroup
                        onChange={(e) => {
                          if (isDetail) return;
                          setOrderType(parseInt(e));
                          if (parseInt(e) === -1) {
                            setSaleInvoice((prev) => ({
                              ...prev,
                              InvJournalMemo:
                                invoice.saleInvoice.InvJournalMemo,
                              INJournalMemo: invoice.saleInvoice.INJournalMemo,
                              VoucherType: 200,
                              IsServiceInvoice: false,
                              SaleInvoiceAsOutward: 0,
                            }));
                          } else {
                            setSaleInvoice((prev) => ({
                              ...prev,
                              InvJournalMemo: 'Doanh thu dịch vụ',
                              INJournalMemo: '',
                              VoucherType: 200,
                              IsServiceInvoice: true,
                              SaleInvoiceAsOutward: 1,
                            }));
                          }
                          swapSaleInvoiceDetails();
                        }}
                        value={orderType}
                      >
                        <HStack spacing={5} direction="row">
                          <Radio colorScheme="blue" value={-1}>
                            Hàng hoá
                          </Radio>
                          <Radio colorScheme="blue" value={1}>
                            Dịch vụ
                          </Radio>
                        </HStack>
                      </RadioGroup>
                    </Box>
                  </Stack>
                  <Stack
                    alignItems={['', '', 'center', 'center']}
                    flexDir={['column', 'column', 'row', 'row']}
                    w={'100%'}
                  >
                    <Box w={170}>
                      <span className="required"> Hình thức thanh toán</span>
                    </Box>
                    <Box px={[0, 0, 1, 1]}>
                      <RadioGroup
                        onChange={(e) => {
                          if (isDetail) return;
                          setSaleInvoice((prev) => ({
                            ...prev,
                            VoucherType: parseInt(e),
                          }));
                        }}
                        value={saleInvoice.VoucherType}
                      >
                        <HStack spacing={5} direction="row">
                          <Radio colorScheme="blue" value={200}>
                            Chưa thanh toán
                          </Radio>
                          <Radio colorScheme="blue" value={51}>
                            Tiền mặt
                          </Radio>
                          <Radio colorScheme="blue" value={61}>
                            Chuyển khoản
                          </Radio>
                        </HStack>
                      </RadioGroup>
                    </Box>
                  </Stack>
                  <Stack
                    alignItems={['', '', 'center', 'center']}
                    flexDir={['column', 'column', 'row', 'row']}
                    w={'100%'}
                  >
                    <Box w={170}>Nộp vào tài khoản</Box>
                    <Box px={[0, 0, 1, 1]}>
                      <Input
                        maxW={150}
                        rounded={6}
                        placeholder="Nhập tài khoản nộp"
                        size="md"
                        value={saleInvoice?.BankAccount ?? ''}
                        disabled={isDetail}
                        onChange={(e) => {
                          if (!isNumeric(e.target.value)) return;
                          setSaleInvoice({
                            ...saleInvoice,
                            BankAccount: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <Box px={[0, 0, 1, 1]} flex={1}>
                      <Input
                        rounded={6}
                        placeholder="Nhập tên tài khoản nộp"
                        size={'md'}
                        value={saleInvoice?.BankName ?? ''}
                        disabled={isDetail}
                        onChange={(e) => {
                          setSaleInvoice({
                            ...saleInvoice,
                            BankName: e.target.value,
                          });
                        }}
                      />
                    </Box>
                  </Stack>
                  <Stack
                    alignItems={['', '', 'center', 'center']}
                    flexDir={['column', 'column', 'row', 'row']}
                    w={'100%'}
                  >
                    <Box w={170}>Diễn giải thuế</Box>
                    <Box px={[0, 0, 1, 1]} flex={1}>
                      <Input
                        value={saleInvoice?.VATDescription ?? ''}
                        onChange={(e) => {
                          setSaleInvoice({
                            ...saleInvoice,
                            VATDescription: e.target.value,
                          });
                        }}
                        rounded={6}
                        disabled={isDetail}
                        placeholder="Nhập diễn giải thuế"
                        size="md"
                      />
                    </Box>
                    <Box w={170} textAlign={'end'}>
                      Diễn giải xuất kho
                    </Box>
                    <Box px={[0, 0, 1, 1]} flex={1}>
                      <Input
                        disabled={isDetail}
                        value={
                          saleInvoice?.INJournalMemo ?? 'Xuất bán hàng hoá'
                        }
                        onChange={(e) => {
                          setSaleInvoice({
                            ...saleInvoice,
                            INJournalMemo: e.target.value,
                          });
                        }}
                        rounded={6}
                        placeholder="Nhập diễn giải xuất kho"
                        size={'md'}
                      />
                    </Box>
                  </Stack>
                </VStack>

                <Box pl={[0, 0, 0, 3]} flex={3}>
                  <Text
                    textTransform={'uppercase'}
                    w={'100%'}
                    fontSize={16}
                    color={'#054169'}
                    fontWeight="bold"
                  >
                    chứng từ
                  </Text>
                  <VStack>
                    <HStack w={'100%'} flex={1}>
                      <Box flex={2}>
                        <span>Mẫu số</span>
                      </Box>
                      <Box flex={3}>
                        <Select
                          className={
                            ''
                            // (productValue?.value ?? '') === '' &&
                            // !isDetail &&
                            // !isSetProduct
                            //   ? 'react-select-error'
                            //   : ''
                          }
                          placeholder="Lựa chọn"
                          value={invoiceTypeValue}
                          menuPosition="fixed"
                          isClearable={true}
                          isDisabled={isDetail}
                          onChange={(e) => {
                            setInvoiceTypeValue(e);
                            setSaleInvoice({
                              ...saleInvoice,
                              InvSeries: e.label,
                              InvoiceTypeID: e.value,
                            });
                          }}
                          options={invoiceTypesRecordsets.map((item) => ({
                            label: item.InvoiceTypeName,
                            value: item.InvoiceTypeID,
                          }))}
                        />
                      </Box>
                    </HStack>
                    <HStack w={'100%'} flex={1}>
                      <Box flex={2}>
                        <span className="required">Kí hiệu</span>
                      </Box>
                      <Box flex={3}>
                        <Input
                          rounded={6}
                          placeholder="Nhập kí hiệu"
                          size={'md'}
                          disabled={isDetail}
                          value={saleInvoice?.InvSeries ?? ''}
                          onChange={(e) => {
                            setSaleInvoice({
                              ...saleInvoice,
                              InvSeries: e.target.value,
                            });
                          }}
                        />
                      </Box>
                    </HStack>
                    <HStack w={'100%'} flex={1}>
                      <Box flex={2}>
                        <span className="required">Ngày ghi sổ</span>
                      </Box>
                      <Box flex={3}>
                        <DatePicker
                          customInput={<CustomInputCalendar />}
                          wrapperClassName="icon-calendar"
                          dateFormat={'dd/MM/yyyy'}
                          disabled={isDetail}
                          selected={
                            !!saleInvoice?.PostedDate
                              ? new Date(saleInvoice.PostedDate)
                              : null
                          }
                          onChange={(date) => {
                            setSaleInvoice({
                              ...saleInvoice,
                              PostedDate: date,
                            });
                          }}
                        />
                      </Box>
                    </HStack>
                    <HStack w={'100%'} flex={1}>
                      <Box flex={2}>Ngày hoá đơn</Box>
                      <Box flex={3}>
                        <DatePicker
                          customInput={<CustomInputCalendar />}
                          wrapperClassName="icon-calendar"
                          dateFormat={'dd/MM/yyyy'}
                          disabled={isDetail}
                          selected={
                            !!saleInvoice?.VoucherDate
                              ? new Date(saleInvoice.VoucherDate)
                              : null
                          }
                          onChange={(date) => {
                            setSaleInvoice({
                              ...saleInvoice,
                              VoucherDate: date,
                            });
                          }}
                        />
                      </Box>
                    </HStack>
                    <HStack w={'100%'} flex={1}>
                      <Box flex={2}>Số hoá đơn</Box>
                      <Box flex={3}>
                        <Input
                          rounded={6}
                          placeholder="Nhập số hoá đơn"
                          size={'md'}
                          disabled={isDetail}
                          value={saleInvoice?.InvNo ?? ''}
                          onChange={(e) => {
                            setSaleInvoice({
                              ...saleInvoice,
                              InvNo: e.target.value,
                            });
                          }}
                        />
                      </Box>
                    </HStack>
                    <HStack w={'100%'} flex={1}>
                      <Box flex={2}>Số phiếu xuất</Box>
                      <Box flex={3}>
                        <Input
                          rounded={6}
                          placeholder="Nhập số phiếu xuất"
                          size={'md'}
                          disabled={isDetail}
                          value={saleInvoice?.INVoucherNo ?? ''}
                          onChange={(e) => {
                            setSaleInvoice({
                              ...saleInvoice,
                              INVoucherNo: e.target.value,
                            });
                          }}
                        />
                      </Box>
                    </HStack>
                    <HStack w={'100%'} flex={1}>
                      <Box flex={2}>
                        <span className="required">Hạn thanh toán</span>
                      </Box>
                      <Box flex={3}>
                        <DatePicker
                          customInput={<CustomInputCalendar />}
                          wrapperClassName="icon-calendar"
                          dateFormat={'dd/MM/yyyy'}
                          disabled={isDetail}
                          selected={
                            !!saleInvoice?.DueDate
                              ? new Date(saleInvoice.DueDate)
                              : null
                          }
                          onChange={(date) => {
                            setSaleInvoice({
                              ...saleInvoice,
                              DueDate: date,
                            });
                          }}
                        />
                      </Box>
                    </HStack>
                    {/* <HStack w={'100%'} flex={1}>
                      <Box flex={2}>PC/UNC</Box>
                      <Box flex={3}>
                        <Input
                          rounded={6}
                          placeholder="Nhập số phiếu PC/UNC"
                          size={'md'}
                          value={saleInvoice?.CABAVoucherNo ?? ''}
                          disabled={isDetail}
                          onChange={(e) => {
                            setSaleInvoice({
                              ...saleInvoice,
                              CABAVoucherNo: e.target.value,
                            });
                          }}
                        />
                      </Box>
                    </HStack> */}
                  </VStack>
                </Box>
              </Stack>
              <HStack mb={2} alignItems={'flex-end'}>
                <Box
                  style={{
                    padding: '8px 12px',
                    color: '#054169',
                    fontWeight: 500,
                    borderBottom:
                      tab === 0 ? '2px solid #F07504' : '2px solid #666666',
                  }}
                  onClick={() => setTab(0)}
                  fontSize={[12, 12, 16, 16]}
                  textTransform={'uppercase'}
                >
                  tiền hàng
                </Box>
                <Box
                  style={{
                    padding: '8px 12px',
                    color: '#054169',
                    fontWeight: 500,
                    margin: 0,
                    borderBottom:
                      tab === 1 ? '2px solid #F07504' : '2px solid #666666',
                  }}
                  onClick={() => setTab(1)}
                  fontSize={[12, 12, 16, 16]}
                  textTransform={'uppercase'}
                >
                  thuế, giá vốn
                </Box>
                <Box
                  style={{
                    padding: '8px 12px',
                    color: '#054169',
                    fontWeight: 500,
                    margin: 0,
                    borderBottom:
                      tab === 2 ? '2px solid #F07504' : '2px solid #666666',
                  }}
                  onClick={() => setTab(2)}
                  fontSize={[12, 12, 16, 16]}
                  textTransform={'uppercase'}
                >
                  hóa đơn
                </Box>

                <Box
                  flex={1}
                  style={{
                    borderBottom: '2px solid #666666',
                    margin: 0,
                  }}
                ></Box>
              </HStack>
              <Box>{renderTable}</Box>
              {/* <Tabs
              onChange={(value) => {
                if (value === tab) return;
                setTab((__) => value);
              }}
              mt={3}
            >
              <TabList>
                <Tab fontSize={[12, 12, 16, 16]} textTransform={'uppercase'}>
                  tiền hàng
                </Tab>
                <Tab fontSize={[12, 12, 16, 16]} textTransform={'uppercase'}>
                  hóa đơn
                </Tab>
                <Tab fontSize={[12, 12, 16, 16]} textTransform={'uppercase'}>
                  chi phí
                </Tab>
                <Tab fontSize={[12, 12, 16, 16]} textTransform={'uppercase'}>
                  chiết khấu
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel overflowX={'auto'}>{renderTable}</TabPanel>
                <TabPanel overflowX={'auto'}>{renderTable}</TabPanel>
                <TabPanel overflowX={'auto'}>{renderTable}</TabPanel>
                <TabPanel overflowX={'auto'}>{renderTable}</TabPanel>
              </TabPanels>
            </Tabs> */}
            </CardBody>
          </Card>
        </form>
      </Flex>
      {(isGettingProducts ||
        isGettingPurchasePurposes ||
        isCreatingInvoice ||
        isUpdatingInvoiceStatus) && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100vh',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner colorScheme="blue" size="xl" />
        </div>
      )}
    </div>
  );
}

export default OrderSellAdd;
