import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import TypeActions from 'redux/typeActions';
import ServiceURL from 'services/ServiceURL';
import { GET, POST, CUSTOM, PATCH } from 'services/ServiceBase';

export function* getInvoices(data) {
  const url = `${ServiceURL.invoices}?sortBy=createdAt:desc`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: TypeActions.GET_INVOICES_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: TypeActions.GET_INVOICES_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_INVOICES_FAILED,
      error: error?.response?.data?.message ?? '',
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message ?? '');
  }
}

export function* getInvoice(data) {
  const url = ServiceURL.loadInvoice;
  const callback = data.callback;
  const formData = new FormData();
  formData.append('xmlFile', data.body.xmlFile);
  formData.append('invoiceProvider', data.body.invoiceProvider);
  try {
    const res = yield call(
      CUSTOM,
      url,
      formData,
      'post',
      ServiceURL.BASE_URL,
      'multipart/form-data'
    );
    if (res.message && !_.isEmpty(res?.message)) {
      yield put({
        type: TypeActions.GET_INVOICE_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.GET_INVOICE_SUCCESS,
        data: res,
      });
      !!callback?.success && callback.success(res);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_INVOICE_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* getProducts(data) {
  const url = `${ServiceURL.execProc}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(POST, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: TypeActions.GET_PRODUCTS_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: TypeActions.GET_PRODUCTS_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_PRODUCTS_FAILED,
      error: error?.response?.data?.message ?? '',
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message ?? '');
  }
}

export function* getPurchasePurposes(data) {
  const url = `${ServiceURL.execProc}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(POST, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: TypeActions.GET_PURCHASE_PURPOSES_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: TypeActions.GET_PURCHASE_PURPOSES_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_PURCHASE_PURPOSES_FAILED,
      error: error?.response?.data?.message ?? '',
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message ?? '');
  }
}

export function* createInvoice(data) {
  const url =
    data.body?.isSaleInvoice === true
      ? `${ServiceURL.invoices}/saleInvoice`
      : `${ServiceURL.invoices}`;
  const callback = data.callback;
  delete data.body.isSaleInvoice;
  try {
    const res = yield call(POST, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: TypeActions.CREATE_INVOICE_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: TypeActions.CREATE_INVOICE_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.CREATE_INVOICE_FAILED,
      error: error?.response?.data?.message ?? '',
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message ?? '');
  }
}

export function* saveInvoices(data) {
  const url = `${ServiceURL.saveinvoices}?${data?.query ?? ''}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: TypeActions.SAVE_INVOICES_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: TypeActions.SAVE_INVOICES_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.SAVE_INVOICES_FAILED,
      error: error?.response?.data?.message ?? '',
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message ?? '');
  }
}

export function* saveInvoice(data) {
  const url = `${ServiceURL.saveinvoices}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: TypeActions.SAVE_INVOICE_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: TypeActions.SAVE_INVOICE_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.SAVE_INVOICE_FAILED,
      error: error?.response?.data?.message ?? '',
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message ?? '');
  }
}

export function* updateSaveInvoiceStatus(data) {
  const url = `${ServiceURL.saveinvoices}/${data.param}`;
  const callback = data.callback;
  try {
    const res = yield call(PATCH, url, data.body);
    if (!!res.data?.error?.message) {
      yield put({
        type: TypeActions.UPDATE_SAVE_INVOICE_STATUS_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: TypeActions.UPDATE_SAVE_INVOICE_STATUS_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.UPDATE_SAVE_INVOICE_STATUS_FAILED,
      error: error?.response?.data?.message ?? '',
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message ?? '');
  }
}

export function* getStocks(data) {
  const url = `${ServiceURL.execProc}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(POST, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: TypeActions.GET_STOCKS_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: TypeActions.GET_STOCKS_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_STOCKS_FAILED,
      error: error?.response?.data?.message ?? '',
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message ?? '');
  }
}

export function* getStatistics(data) {
  const url = `${ServiceURL.execProc}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(POST, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: TypeActions.GET_STATISTICS_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: TypeActions.GET_STATISTICS_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_STATISTICS_FAILED,
      error: error?.response?.data?.message ?? '',
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message ?? '');
  }
}

export function* getInvoiceTypes(data) {
  const url = `${ServiceURL.execProc}?${data.query}`;
  const callback = data.callback;
  try {
    const res = yield call(POST, url);
    if (!!res.data?.error?.message) {
      yield put({
        type: TypeActions.GET_INVOICE_TYPES_FAILED,
        error: res.data?.error?.message,
      });
      !!callback?.failed && callback.failed(res.data.error.message);
    } else {
      yield put({
        type: TypeActions.GET_INVOICE_TYPES_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_INVOICE_TYPES_FAILED,
      error: error?.response?.data?.message ?? '',
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message ?? '');
  }
}

export default function* invoiceSaga() {
  yield takeLatest(TypeActions.GET_INVOICE_REQUEST, getInvoice);
  yield takeLatest(TypeActions.GET_INVOICES_REQUEST, getInvoices);
  yield takeLatest(TypeActions.GET_PRODUCTS_REQUEST, getProducts);
  yield takeLatest(TypeActions.GET_INVOICE_TYPES_REQUEST, getInvoiceTypes);
  yield takeLatest(TypeActions.GET_STOCKS_REQUEST, getStocks);
  yield takeLatest(TypeActions.GET_STATISTICS_REQUEST, getStatistics);
  yield takeLatest(
    TypeActions.GET_PURCHASE_PURPOSES_REQUEST,
    getPurchasePurposes
  );
  yield takeLatest(TypeActions.CREATE_INVOICE_REQUEST, createInvoice);
  yield takeLatest(TypeActions.SAVE_INVOICES_REQUEST, saveInvoices);
  yield takeLatest(TypeActions.SAVE_INVOICE_REQUEST, saveInvoice);
  yield takeLatest(
    TypeActions.UPDATE_SAVE_INVOICE_STATUS_REQUEST,
    updateSaveInvoiceStatus
  );
}
