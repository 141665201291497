import TypeActions from 'redux/typeActions';

export const getCompanys = (query, callback) => {
  return {
    type: TypeActions.GET_COMPANYS_REQUEST,
    query,
    callback,
  };
};

export default {
  getCompanys,
};
