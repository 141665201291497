import { all, fork } from 'redux-saga/effects';

import accountSaga from './accountSaga';
import invoiceSaga from './invoiceSaga';
import orderTemplateSaga from './orderTemplateSaga';
import roleSaga from './roleSaga';
import companySaga from './companySaga';

export function* rootSagas() {
  yield all([fork(accountSaga)]);
  yield all([fork(invoiceSaga)]);
  yield all([fork(orderTemplateSaga)]);
  yield all([fork(roleSaga)]);
  yield all([fork(companySaga)]);
}
export default rootSagas;
