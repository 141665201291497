import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import AuthLayout from 'layouts/Auth.js';
import AdminLayout from 'layouts/Admin.js';
import { ChakraProvider } from '@chakra-ui/react';
import { createBrowserHistory } from 'history';
// Custom Chakra theme
import theme from 'theme/theme.js';
import { Provider as StoreProvider } from 'react-redux';
import store from './store';
import 'index.css';
import 'react-datepicker/dist/react-datepicker.css';
const history = createBrowserHistory();
ReactDOM.render(
  <StoreProvider store={store}>
    <ChakraProvider theme={theme} resetCss={false} position="relative">
      <Router history={history}>
        <Switch>
          <Route path={`/auth`} component={AuthLayout} />
          <Route path={`/`} component={AdminLayout} />
          <Redirect from={`*`} to="/auth/login" />
        </Switch>
      </Router>
    </ChakraProvider>
  </StoreProvider>,
  document.getElementById('root')
);
