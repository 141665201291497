import { combineReducers } from 'redux';
import accountReducer from './accountReducer';
import invoiceReducer from './invoiceReducer';
import orderTemplateReducer from './orderTemplateReducer';
import roleReducer from './roleReducer';
import companyReducer from './companyReducer';

const rootReducers = combineReducers({
  accountReducer,
  invoiceReducer,
  orderTemplateReducer,
  roleReducer,
  companyReducer,
});
export default rootReducers;
