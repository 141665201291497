import TypeActions from 'redux/typeActions';

const initialState = {
  isGetOrderTemplates: false,
  isGetOrderTemplate: false,
  isCreateOrderTemplate: false,
  isUpdateOrderTemplate: false,
  isDeleteOrderTemplate: false,
  isGetFieldsOrderTemplate: false,
  orderTemplates: { results: [] },
  orderTemplate: {},
  fieldsOrderTemplate: [],

  errors: {
    getOrderTemplates: '',
    createOrderTemplate: '',
    updateOrderTemplate: '',
    deleteOrderTemplate: '',
    getOrderTemplate: '',
  },
};

const orderTemplateReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case TypeActions.GET_ORDER_TEMPLATES_REQUEST:
      return {
        ...state,
        orderTemplates: { results: [] },
        isGetOrderTemplates: true,
        errors: {
          ...state.errors,
          getOrderTemplates: '',
        },
      };
    case TypeActions.GET_ORDER_TEMPLATES_SUCCESS:
      return {
        ...state,
        orderTemplates: actions.data || { results: [] },
        isGetOrderTemplates: false,
        errors: {
          ...state.errors,
          getOrderTemplates: '',
        },
      };

    case TypeActions.GET_ORDER_TEMPLATES_FAILED:
      return {
        ...state,
        orderTemplates: { results: [] },
        isGetOrderTemplates: false,
        errors: {
          ...state.errors,
          getOrderTemplates: actions.error,
        },
      };

    case TypeActions.GET_ORDER_TEMPLATE_REQUEST:
      return {
        ...state,
        // orderTemplate: {},
        isGetOrderTemplate: true,
        errors: {
          ...state.errors,
          getOrderTemplate: '',
        },
      };

    case TypeActions.GET_ORDER_TEMPLATE_SUCCESS:
      return {
        ...state,
        orderTemplate: actions.data || {},
        isGetOrderTemplate: false,
        errors: {
          ...state.errors,
          getOrderTemplate: '',
        },
      };

    case TypeActions.GET_ORDER_TEMPLATE_FAILED:
      return {
        ...state,
        orderTemplate: {},
        isGetOrderTemplate: false,
        errors: {
          ...state.errors,
          getOrderTemplate: actions.error,
        },
      };

    case TypeActions.CREATE_ORDER_TEMPLATE_REQUEST:
      return {
        ...state,
        isCreateOrderTemplate: true,
        errors: {
          ...state.errors,
          createOrderTemplate: '',
        },
      };

    case TypeActions.CREATE_ORDER_TEMPLATE_SUCCESS:
      return {
        ...state,
        isCreateOrderTemplate: false,
        errors: {
          ...state.errors,
          createOrderTemplate: '',
        },
      };

    case TypeActions.CREATE_ORDER_TEMPLATE_FAILED:
      return {
        ...state,
        isCreateOrderTemplate: false,
        errors: {
          ...state.errors,
          createOrderTemplate: actions.error,
        },
      };

    case TypeActions.UPDATE_ORDER_TEMPLATE_REQUEST:
      return {
        ...state,
        isUpdateOrderTemplate: true,
        errors: {
          ...state.errors,
          updateOrderTemplate: '',
        },
      };

    case TypeActions.UPDATE_ORDER_TEMPLATE_SUCCESS:
      return {
        ...state,
        isUpdateOrderTemplate: false,
        errors: {
          ...state.errors,
          updateOrderTemplate: '',
        },
      };

    case TypeActions.UPDATE_ORDER_TEMPLATE_FAILED:
      return {
        ...state,
        isUpdateOrderTemplate: false,
        errors: {
          ...state.errors,
          updateOrderTemplate: actions.error,
        },
      };

    case TypeActions.DELETE_ORDER_TEMPLATE_REQUEST:
      return {
        ...state,
        isDeleteOrderTemplate: true,
        errors: {
          ...state.errors,
          deleteOrderTemplate: '',
        },
      };

    case TypeActions.DELETE_ORDER_TEMPLATE_SUCCESS:
      return {
        ...state,
        isDeleteOrderTemplate: false,
        errors: {
          ...state.errors,
          deleteOrderTemplate: '',
        },
      };

    case TypeActions.DELETE_ORDER_TEMPLATE_FAILED:
      return {
        ...state,
        isDeleteOrderTemplate: false,
        errors: {
          ...state.errors,
          deleteOrderTemplate: actions.error,
        },
      };

    case TypeActions.GET_FIELDS_ORDER_TEMPLATE_REQUEST:
      return {
        ...state,
        fieldsOrderTemplate: [],
        isGetFieldsOrderTemplate: true,
        errors: {
          ...state.errors,
        },
      };

    case TypeActions.GET_FIELDS_ORDER_TEMPLATE_SUCCESS:
      return {
        ...state,
        orderTemplate: actions.data || {},
        fieldsOrderTemplate: false,
        errors: {
          ...state.errors,
        },
      };

    case TypeActions.GET_FIELDS_ORDER_TEMPLATE_FAILED:
      return {
        ...state,
        orderTemplate: {},
        fieldsOrderTemplate: false,
        errors: {
          ...state.errors,
        },
      };
    default:
      return {
        ...state,
      };
  }
};
export default orderTemplateReducer;
