import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import TypeActions from 'redux/typeActions';
import ServiceURL from 'services/ServiceURL';
import { DELETE, GET, PATCH, POST } from 'services/ServiceBase';

export function* accountLogIn(data) {
  const url = ServiceURL.logIn;

  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);

    if (res.message && !_.isEmpty(res?.message)) {
      yield put({
        type: TypeActions.ACCOUNT_LOGIN_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.ACCOUNT_LOGIN_SUCCESS,
        data: res.data.user,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.ACCOUNT_LOGIN_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* accountLogOut(data) {
  const url = ServiceURL.logOut;
  localStorage.clear();
  try {
    const res = yield call(POST, url, data.body);

    if (res.message && !_.isEmpty(res?.message)) {
      yield put({
        type: TypeActions.ACCOUNT_LOGOUT_FAILED,
        error: res?.error?.response?.data?.message,
      });
    } else {
      yield put({
        type: TypeActions.ACCOUNT_LOGOUT_SUCCESS,
      });
    }
  } catch (error) {
    yield put({
      type: TypeActions.ACCOUNT_LOGOUT_FAILED,
      error: error?.response?.data?.message,
    });
  }
}

export function* forgotPassword(data) {
  const url = ServiceURL.forgotPassword;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res?.message)) {
      yield put({
        type: TypeActions.ACCOUNT_FORGOT_PASSWORD_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.ACCOUNT_FORGOT_PASSWORD_SUCCESS,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.ACCOUNT_FORGOT_PASSWORD_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* resetPassword(data) {
  const url = ServiceURL.resetPassword + '?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res?.message)) {
      yield put({
        type: TypeActions.ACCOUNT_RESET_PASSWORD_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.ACCOUNT_RESET_PASSWORD_SUCCESS,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.ACCOUNT_RESET_PASSWORD_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* getCurrentAccount(data) {
  const url = ServiceURL.users + '/' + data.param + '?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res?.message)) {
      yield put({
        type: TypeActions.GET_CURRENT_ACCOUNT_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.GET_CURRENT_ACCOUNT_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.message);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_CURRENT_ACCOUNT_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* accountChangePassword(data) {
  const url =
    ServiceURL.users + '/' + ServiceURL.changePassword + '/' + data.param;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res?.message)) {
      yield put({
        type: TypeActions.ACCOUNT_CHANGE_PASSWORD_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.ACCOUNT_CHANGE_PASSWORD_SUCCESS,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.ACCOUNT_CHANGE_PASSWORD_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* getAccounts(data) {
  const url = ServiceURL.users + '?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);

    if (res.message && !_.isEmpty(res?.message)) {
      yield put({
        type: TypeActions.GET_ACCOUNTS_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.GET_ACCOUNTS_SUCCESS,
        data: res.data,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_ACCOUNTS_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* getAccount(data) {
  const url = ServiceURL.users + '/' + data.param + '?' + data.query;
  const callback = data.callback;
  try {
    const res = yield call(GET, url);
    if (res.message && !_.isEmpty(res?.message)) {
      yield put({
        type: TypeActions.GET_ACCOUNT_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.GET_ACCOUNT_SUCCESS,
        data: res.data,
      });
      callback.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.GET_ACCOUNT_FAILED,
      error: error?.response?.data?.message,
    });
    callback.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* createAccount(data) {
  const url = ServiceURL.users;
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res?.message)) {
      yield put({
        type: TypeActions.CREATE_ACCOUNT_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.CREATE_ACCOUNT_SUCCESS,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.CREATE_ACCOUNT_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* updateAccount(data) {
  const url = ServiceURL.users + '/' + data.param;
  const callback = data.callback;
  try {
    const res = yield call(PATCH, url, data.body);
    if (res.message && !_.isEmpty(res?.message)) {
      yield put({
        type: TypeActions.UPDATE_ACCOUNT_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.UPDATE_ACCOUNT_SUCCESS,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.UPDATE_ACCOUNT_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* deleteAccount(data) {
  const url = ServiceURL.users + '/' + data.param;
  const callback = data.callback;
  try {
    const res = yield call(DELETE, url);
    if (res.message && !_.isEmpty(res?.message)) {
      yield put({
        type: TypeActions.DELETE_ACCOUNT_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.DELETE_ACCOUNT_SUCCESS,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.DELETE_ACCOUNT_FAILED,
      error: error?.response?.data?.message,
    });
    callback.failed && callback.failed(error?.response?.data?.message);
  }
}

export function* setPassword(data) {
  const url = ServiceURL.users + '/set-password';
  const callback = data.callback;
  try {
    const res = yield call(POST, url, data.body);
    if (res.message && !_.isEmpty(res?.message)) {
      yield put({
        type: TypeActions.SET_PASSWORD_FAILED,
        error: res?.error?.response?.data?.message,
      });
      !!callback?.failed &&
        callback.failed(res?.error?.response?.data?.message);
    } else {
      yield put({
        type: TypeActions.SET_PASSWORD_SUCCESS,
      });
      !!callback?.success && callback.success(res.data);
    }
  } catch (error) {
    yield put({
      type: TypeActions.SET_PASSWORD_FAILED,
      error: error?.response?.data?.message,
    });
    !!callback?.failed && callback.failed(error?.response?.data?.message);
  }
}

export default function* accountSaga() {
  yield takeLatest(TypeActions.ACCOUNT_LOGIN_REQUEST, accountLogIn);
  yield takeLatest(TypeActions.ACCOUNT_LOGOUT_REQUEST, accountLogOut);
  yield takeLatest(TypeActions.ACCOUNT_FORGOT_PASSWORD_REQUEST, forgotPassword);
  yield takeLatest(TypeActions.ACCOUNT_RESET_PASSWORD_REQUEST, resetPassword);
  yield takeLatest(TypeActions.GET_CURRENT_ACCOUNT_REQUEST, getCurrentAccount);
  yield takeLatest(TypeActions.GET_ACCOUNTS_REQUEST, getAccounts);
  yield takeLatest(TypeActions.GET_ACCOUNT_REQUEST, getAccount);
  yield takeLatest(TypeActions.CREATE_ACCOUNT_REQUEST, createAccount);
  yield takeLatest(TypeActions.UPDATE_ACCOUNT_REQUEST, updateAccount);
  yield takeLatest(TypeActions.DELETE_ACCOUNT_REQUEST, deleteAccount);
  yield takeLatest(TypeActions.SET_PASSWORD_REQUEST, setPassword);
}
