import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { EyeIconClose, EyeIconOpen } from 'components/Icons/Icons';
import React from 'react';

export const InputForm = (props) => {
  const {
    code,
    error,
    label,
    placeholder,
    register,
    isRequired,
    fontSizeLabel,

    ...rest
  } = props;
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  return (
    <FormControl mb={'16px'} isInvalid={error}>
      <FormLabel
        fontSize={fontSizeLabel ?? '14px'}
        fontWeight={'600'}
        htmlFor={code}
      >
        <span className={isRequired ? 'required' : ''}>{label}</span>
      </FormLabel>
      {rest.type === 'password' ? (
        <InputGroup>
          <Input
            id={code}
            isInvalid={error}
            errorBorderColor="red.300"
            autoComplete="new-password"
            {...rest}
            {...register}
            type={show ? 'text' : 'password'}
          />
          <InputRightElement>
            <Button h="1.75rem" size="sm" onClick={handleClick}>
              {show ? <EyeIconClose /> : <EyeIconOpen />}
            </Button>
          </InputRightElement>
        </InputGroup>
      ) : (
        <Input
          id={code}
          isInvalid={error}
          errorBorderColor="red.300"
          autoComplete="new-password"
          {...rest}
          {...register}
        />
      )}
      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  );
};
