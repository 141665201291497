// Chakra imports
import {
  Button,
  Flex,
  HStack,
  Spinner,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Radio,
  RadioGroup,
  useColorModeValue,
} from '@chakra-ui/react';
import Select from 'react-select';
import _ from 'lodash';
// Custom components
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TableRow from './components/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import { invoiceActions } from 'redux/actions';
import { Paginate } from 'react-paginate-chakra-ui';
import queryString from 'query-string';
import moment from 'moment';

function Orders() {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    query,
    saveInvoices,
    isSavingInvoice,
    isSavingInvoices,
    products,
    stocks,
    purchasePurposes,
    isGettingPurchasePurposes,
    isGettingProducts,
    isGettingStocks,
    statistics,
    isGettingStatistics,
  } = useSelector((state) => state.invoiceReducer);

  function setQuery(q) {
    dispatch(invoiceActions.setQuery(q));
  }

  const results = _.get(saveInvoices, 'results', []);
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  useEffect(() => {
    // dispatch(invoiceActions.getInvoices());
    const accountId = localStorage.getItem('accountId');
    dispatch(
      invoiceActions.saveInvoices(
        queryString.stringify(
          _.merge(query, { accountId, invoiceType: 'purchase' })
        )
      )
    );
  }, [query]);

  useEffect(() => {
    if (products?.recordsets === undefined && !isGettingProducts) {
      dispatch(
        invoiceActions.getProducts(
          queryString.stringify({
            procName: 'Proc_SelectItem',
          })
        )
      );
    }
    if (stocks?.recordsets === undefined && !isGettingStocks) {
      dispatch(
        invoiceActions.getStocks(
          queryString.stringify({
            procName: 'Proc_SelectStock',
          })
        )
      );
    }
    if (
      purchasePurposes?.recordsets === undefined &&
      !isGettingPurchasePurposes
    ) {
      dispatch(
        invoiceActions.getPurchasePurposes(
          queryString.stringify({
            procName: 'Proc_SelectPurchasePurpose',
          })
        )
      );
    }
    if (statistics?.recordsets === undefined && !isGettingStatistics) {
      dispatch(
        invoiceActions.getStatistics(
          queryString.stringify({
            procName: 'Proc_SelectStatisticItem',
          })
        )
      );
    }
  }, [
    isGettingProducts,
    isGettingPurchasePurposes,
    isGettingStocks,
    isGettingStatistics,
  ]);

  return (
    <Flex
      style={{ position: 'relative' }}
      direction="column"
      pt={{ base: '120px', md: '75px' }}
    >
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <HStack justifyContent={'space-between'}>
            <HStack>
              <Text
                fontSize={[10, 12, 14, 16]}
                color={'#054169'}
                fontWeight="bold"
              >
                DANH SÁCH HÓA ĐƠN MUA HÀNG HÔM NAY
              </Text>
              <RadioGroup
                onChange={(e) => {
                  setQuery({
                    ...query,
                    saveInvoiceStatus: parseInt(e),
                  });
                }}
                value={query.saveInvoiceStatus}
              >
                <HStack spacing={5} direction="row">
                  <Radio colorScheme="blue" value={0}>
                    Chờ duyệt
                  </Radio>
                  <Radio colorScheme="blue" value={1}>
                    Đã duyệt
                  </Radio>
                </HStack>
              </RadioGroup>

              <Select
                placeholder="Lựa chọn tháng"
                value={{ label: query.month, value: query.month }}
                isClearable={false}
                onChange={(e) => {
                  setQuery({
                    ...query,
                    month: e.value,
                  });
                }}
                options={Array.from({ length: 12 }, (_, i) => i + 1).map(
                  (item) => ({
                    label: item,
                    value: item,
                  })
                )}
              />
              <Select
                placeholder="Lựa chọn năm"
                value={{ label: query.year, value: query.year }}
                isClearable={false}
                onChange={(e) => {
                  setQuery({
                    ...query,
                    year: e.value,
                  });
                }}
                options={Array.from({ length: 12 }, (_, i) => i).map(
                  (item) => ({
                    label: moment().year() - item,
                    value: moment().year() - item,
                  })
                )}
              />
              {isSavingInvoices && <Spinner size={'sm'} />}
            </HStack>

            <Button
              onClick={() => {
                history.push('/order-add');
              }}
              fontSize={[10, 12, 14, 16]}
              colorScheme="blue"
            >
              THÊM MỚI
            </Button>
          </HStack>
        </CardHeader>
        <CardBody h={'60vh'} overflowX="scroll">
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                  borderColor={borderColor}
                  fontSize={[10, 10, 12, 12]}
                  px={['5px', '5px', '24px', '24px']}
                  py={['5px', '5px', '12px', '12px']}
                  color="#054169"
                  textAlign={'center'}
                >
                  Hành động
                </Th>
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  fontSize={[10, 10, 12, 12]}
                  px={['5px', '5px', '24px', '24px']}
                  py={['5px', '5px', '12px', '12px']}
                  color="#054169"
                >
                  Ngày ghi sổ
                </Th>
                <Th
                  borderColor={borderColor}
                  fontSize={[10, 10, 12, 12]}
                  px={['5px', '5px', '24px', '24px']}
                  py={['5px', '5px', '12px', '12px']}
                  color="#054169"
                >
                  Ngày chứng từ
                </Th>
                <Th
                  borderColor={borderColor}
                  fontSize={[10, 10, 12, 12]}
                  px={['5px', '5px', '24px', '24px']}
                  py={['5px', '5px', '12px', '12px']}
                  color="#054169"
                >
                  Số chứng từ
                </Th>
                <Th
                  borderColor={borderColor}
                  fontSize={[10, 10, 12, 12]}
                  px={['5px', '5px', '24px', '24px']}
                  py={['5px', '5px', '12px', '12px']}
                  color="#054169"
                >
                  Diễn giải
                </Th>
                <Th
                  borderColor={borderColor}
                  fontSize={[10, 10, 12, 12]}
                  px={['5px', '5px', '24px', '24px']}
                  py={['5px', '5px', '12px', '12px']}
                  color="#054169"
                >
                  Nhà cung cấp
                </Th>
                <Th
                  borderColor={borderColor}
                  fontSize={[10, 10, 12, 12]}
                  px={['5px', '5px', '24px', '24px']}
                  py={['5px', '5px', '12px', '12px']}
                  color="#054169"
                >
                  Loại chứng từ
                </Th>
                <Th
                  borderColor={borderColor}
                  fontSize={[10, 10, 12, 12]}
                  px={['5px', '5px', '24px', '24px']}
                  py={['5px', '5px', '12px', '12px']}
                  color="#054169"
                  textAlign={'right'}
                >
                  Tiền thanh toán
                </Th>
                <Th
                  borderColor={borderColor}
                  fontSize={[10, 10, 12, 12]}
                  px={['5px', '5px', '24px', '24px']}
                  py={['5px', '5px', '12px', '12px']}
                  color="#054169"
                  textAlign={'right'}
                >
                  Giá trị nhập kho
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {results.map((row, index, arr) => {
                return (
                  <TableRow
                    row={row}
                    isLast={index === arr.length - 1 ? true : false}
                    key={index}
                    saveInvoiceStatus={query.saveInvoiceStatus}
                  />
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Paginate
            page={(saveInvoices?.page ?? 1) - 1}
            count={saveInvoices?.totalResults ?? 0}
            pageSize={query.limit ?? 50}
            onPageChange={(value) => {
              setQuery({
                ...query,
                page: value + 1,
              });
            }}
            margin={2}
            shadow="lg"
            fontWeight="blue"
            variant="outline"
            border="2px solid #3182ce"
            colorScheme="blue"
          />
        </div>
      </Card>
      {isSavingInvoice ? (
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'rgba(255,255,255,0.2)',
            width: '100%',
            height: '100%',
            opacity: 0.2,
            borderRadius: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner size="md" />
        </div>
      ) : (
        <></>
      )}
    </Flex>
  );
}

export default Orders;
