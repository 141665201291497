// const { default: TypeActions } = require("redux/TypeActions");
// import TypeActions from 'src/redux/TypeActions';

import TypeActions from 'redux/typeActions';

export const accountLogin = (body, callback) => {
  return {
    type: TypeActions.ACCOUNT_LOGIN_REQUEST,
    body,
    callback,
  };
};

export const accountLogOut = (body, callback) => {
  return {
    type: TypeActions.ACCOUNT_LOGOUT_REQUEST,
    body,
    callback,
  };
};

export const forgotPassword = (body, callback) => {
  return {
    type: TypeActions.ACCOUNT_FORGOT_PASSWORD_REQUEST,
    body,
    callback,
  };
};

export const resetPassword = (body, query, callback) => {
  return {
    type: TypeActions.ACCOUNT_RESET_PASSWORD_REQUEST,
    body,
    query,
    callback,
  };
};

export const getCurrentAccount = (param, query, callback) => {
  return {
    type: TypeActions.GET_CURRENT_ACCOUNT_REQUEST,
    param,
    query,
    callback,
  };
};

export const accountChangePassword = (param, body, callback) => {
  return {
    type: TypeActions.ACCOUNT_CHANGE_PASSWORD_REQUEST,
    param,
    body,
    callback,
  };
};

export const getAccounts = (query, callback) => {
  return {
    type: TypeActions.GET_ACCOUNTS_REQUEST,
    query,
    callback,
  };
};

export const getAccount = (param, query, callback) => {
  return {
    type: TypeActions.GET_ACCOUNT_REQUEST,
    query,
    param,
    callback,
  };
};

export const createAccount = (body, callback) => {
  return {
    type: TypeActions.CREATE_ACCOUNT_REQUEST,
    body,
    callback,
  };
};

export const updateAccount = (param, body, callback) => {
  return {
    type: TypeActions.UPDATE_ACCOUNT_REQUEST,
    param,
    body,
    callback,
  };
};

export const deleteAccount = (param, callback) => {
  return {
    type: TypeActions.DELETE_ACCOUNT_REQUEST,
    param,
    callback,
  };
};

export const setPassword = (body, callback) => {
  return {
    type: TypeActions.SET_PASSWORD_REQUEST,
    param,
    body,
    callback,
  };
};

export default {
  accountLogin,
  accountLogOut,
  forgotPassword,
  resetPassword,
  getCurrentAccount,
  accountChangePassword,
  getAccounts,
  getAccount,
  createAccount,
  updateAccount,
  deleteAccount,
  setPassword,
};
