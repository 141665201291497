import { Box } from '@chakra-ui/react';
import React from 'react';

const Error = ({ message }) => {
  return (
    <Box
      ms={'4px'}
      style={{
        width: '100%',

        fontSize: 10,
        color: '#fb6340',
      }}
    >
      {message}
    </Box>
  );
};

export default Error;
